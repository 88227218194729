<template>
  <div class="layout-bottom">
    <!--<div class="logo">-->
    <!--<img src="~@/assets/img/login/small-logo.png" alt />-->
    <!--</div>-->
    <div class="text">
      Copyright © 2012-{{ new Date().getFullYear() }}
      湖南汉马科技有限公司 版权所有 <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备19027918号-1</a>&nbsp;服务热线：400 999 1468
    </div>
    <!--  -->
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/mixin.scss";
.layout-bottom {
  @include flex;
  width: 100%;
  height: 100px;
  font-weight: 400;
  color: #f5f5f5;
  transition: all ease 0.4s;
  background: #48525c;
  font-size: 14px;
  text-align: center;
  .logo {
    width: 17px;
    height: 20px;
    margin-right: 10px;
  }
  .text {
    // text-align: left;
    width: 100%;
    padding: 0 20px;
    padding-block: 4px;
    a{
      color: #3399fe;
    }
  }
}
</style>
