<template>
  <div class="app-main">
    <transition name="fade-transform" mode="out-in" v-if="!isPaymentRoute">
      <router-view class="router-view" :key="key" />
    </transition>
    <router-view v-else class="router-view" :key="key" />
    <FooterComponent class="footer-component" />
  </div>
</template>

<script>
import FooterComponent from "./Footer.vue";
export default {
  name: "Main",
  components: {
    FooterComponent,
  },
  props: {},
  data() {
    return {
      isPaymentRoute: false,
    };
  },
  watch: {
    $route() {
      this.isPaymentRoute = this.$route.fullPath.includes("/payment");
    },
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/mixin.scss";

.app-main {
  background-repeat: no-repeat;
  background-size: 100% 99.99%;
  min-height: calc(100vh - 60px);
  background: #f8fbff;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.no-transition-enter-active,
.no-transition-leave-active {
  transition: none; /* 禁用过渡 */
}

.no-transition-enter,
.no-transition-leave-to {
  opacity: 1; /* 在进入和离开时保持一致的状态 */
}

.router-view {
  //min-height: calc(100vh - 120px);
}
.footer-component {
  width: 100%;
  height: 60px;
}
@media only screen and (max-width: 1080px) {
}
</style>
