<template>
  <div class="home-page">
    <!-- 标题 -->
    <h1>
      <div class="shine-box">
        <div class="shine-span">论文格式检测与排版</div>
      </div>
    </h1>
    <!-- 副标题 -->
    <h2>AI智能算法，论文格式一键生成，准确、高效、安全</h2>
    <!-- 换行 -->
    <p style="margin-bottom: 20px">专注于为高校学生解决论文格式规范问题</p>
    <template v-if="uploadStatus">
      <div class="uploaded-box">
        <div class="close-button" @click="handleClose">
          <img src="~@/assets/img/home/close.svg" alt="" />
        </div>
        <div class="file-list">
          <div class="header">
            <div>文件名</div>
            <div class="progress-box">上传进度</div>
            <div class="min-width">大小</div>
            <div>操作</div>
          </div>
          <div class="body">
            <div v-for="(file, index) in uploadedFileList" :key="index" class="file-item">
              <div>
                <img src="~@/assets/img/home/word.png" alt="文件图标" />
                {{ file.fileName }}
              </div>
              <div class="progress-box">
                <div class="progress-inner">
                  <template v-if="file.status == 'success'">
                    <div class="progress-text">
                      <div class="img">
                        <img src="~@/assets/img/icon/success.svg" alt="" />
                      </div>
                      <div class="name">上传成功</div>
                    </div>
                  </template>
                  <template v-else-if="file.status == 'fail'">
                    <div class="progress-text">
                      <div class="img">
                        <img src="~@/assets/img/icon/fail.svg" alt="" />
                      </div>
                      <div class="name">上传失败</div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="progress-text">
                      <div class="img">
                        <img src="~@/assets/img/icon/waiting.svg" alt="" />
                      </div>
                      <div class="name">上传中</div>
                    </div>
<!--                    <BasicProcess :progress="file.progress" />-->
                  </template>
                </div>
              </div>
              <div class="min-width">{{ file.fileSize }}</div>
              <div class="flex">
                <div class="btn" @click="handleStart(file, index, 'detection')" :class="{ 'disabled-btn': file.status != 'success' }">
                  <img class="btn-img" src="~@/assets/img/icon/start.svg" v-if="file.status == 'success'" alt="" />
                  <img class="btn-img" src="~@/assets/img/icon/start-disabled.svg" v-else alt="" />
                  开始检测
                </div>
                <div
                  style="margin-left: 6px"
                  class="btn"
                  @click="handleStart(file, index, 'layout')"
                  :class="{ 'disabled-btn': file.status != 'success' }"
                >
                  <img class="btn-img" src="~@/assets/img/icon/start.svg" v-if="file.status == 'success'" alt="" />
                  <img class="btn-img" src="~@/assets/img/icon/start-disabled.svg" v-else alt="" />
                  开始排版
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 上传组件 -->
    <div v-show="!uploadStatus"   @click="handleUploadClick" @dragover.prevent @drop="handleChangeFile">
      <!-- :disabled="disabledUpload"-->
       <el-upload
         class="upload-demo"
         ref="upload"
         accept='.doc,.docx'
         drag
         :action="uploadUrl"
         :headers="headers"
         :on-progress="onProgress"
         :on-success="uploadSuccess"
         :on-error="uploadError"
         :multiple="true"
         :show-file-list="false"
         :before-upload="beforeUpload"
         :auto-upload="false"
         :data="uploadData"
         :file-list="fileList"
         :disabled="disabledUpload"
         :on-change="
           (file, fileList) => {
             handleChangeFile(file, fileList);
           }
         "
       >
         <!-- 上传文件的Logo图片 -->
        <img class="upload-img" src="~@/assets/img/home/upload.png" alt="上传文件" />
        <!-- 提示文字 -->
        <template v-if="fileData">
          <div class="el-upload__text">
            {{ fileData.name }}
          </div>
        </template>
        <template v-else>
          <div class="el-upload__text">拖拽文件到此处<br />或</div>
        </template>
        <el-button type="primary" style="width: 262px; border-radius: 0; font-size: 18px; font-weight: bolder">点击上传</el-button>
        <!-- 提示文字修改 -->
        <div class="el-upload__tip" slot="tip">只能上传doc、docx文件，且不超过{{size}}MB</div>
        <!-- 上传按钮 -->
      </el-upload>
    </div>
<!--    <div v-if="disabledUpload" v-show="!uploadStatus" @click="handleUploadClick">-->
<!--      &lt;!&ndash; :disabled="disabledUpload"&ndash;&gt;-->
<!--      <el-upload-->
<!--          class="upload-demo"-->
<!--          ref="upload"-->
<!--          drag-->
<!--          :action="uploadUrl"-->
<!--          :headers="headers"-->
<!--          :on-progress="onProgress"-->
<!--          :on-success="uploadSuccess"-->
<!--          :on-error="uploadError"-->
<!--          :multiple="true"-->
<!--          :show-file-list="false"-->
<!--          :before-upload="beforeUpload"-->
<!--          :auto-upload="false"-->
<!--          :data="uploadData"-->
<!--          :file-list="fileList"-->

<!--          :on-change="-->
<!--           (file, fileList) => {-->
<!--             handleChangeFile(file, fileList);-->
<!--           }-->
<!--         "-->
<!--      >-->
<!--        &lt;!&ndash; 上传文件的Logo图片 &ndash;&gt;-->
<!--        <img class="upload-img" src="~@/assets/img/home/upload.png" alt="上传文件" />-->
<!--        &lt;!&ndash; 提示文字 &ndash;&gt;-->
<!--        <template v-if="fileData">-->
<!--          <div class="el-upload__text">-->
<!--            {{ fileData.name }}-->
<!--          </div>-->
<!--        </template>-->
<!--        <template v-else>-->
<!--          <div class="el-upload__text">拖拽文件到此处<br />或</div>-->
<!--        </template>-->
<!--        <el-button type="primary" style="width: 262px; border-radius: 0; font-size: 18px; font-weight: bolder">点击上传</el-button>-->
<!--        &lt;!&ndash; 提示文字修改 &ndash;&gt;-->
<!--        <div class="el-upload__tip" slot="tip">只能上传doc、docx文件，且不超过{{size}}MB</div>-->
<!--        &lt;!&ndash; 上传按钮 &ndash;&gt;-->
<!--      </el-upload>-->
<!--    </div>-->
    <div class="square-section">
      <div v-for="(item, index) in squareItems" :key="index" class="square" @click="handleSquareClick(item)">
        <div class="title" :style="{ background: item.color }">
          {{ item.title }}
        </div>
        <div class="content" style="font-size: 16px">
          {{ item.content }}
          <div v-if="item.btnText" class="btn" @click="dd()">
            {{ item.btnText }}
          </div>
        </div>
      </div>
    </div>
    <!-- 他们都在用，合作方的logo用flex写布局 -->
    <!-- <div class="sponsors">
      <div class="title">他们都在用</div>
      <div class="sponsor-container">
        <div class="sponsor-item" v-for="(item, index) in sponsorList" :key="index">
          <img class="img" :src="require(`@/assets/img/home/icon/${item.img}`)" alt="" />
          <div class="text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div> -->
    <login-register-modal :visible.sync="loginRegisterVisible" @ok="loginSuccess" @okNew="loginSuccessNew"  />
    <el-dialog :visible.sync="visible" width="400px" title="插件下载" :before-close="handleCancel3">
      <a-form>
        <a-row>
          <a-col :span="24">
              建设中，敬请期待......
          </a-col>
        </a-row>
      </a-form>
      <template slot="footer">
        <el-button type="primary"  @click="handleCancel3">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/token";
import { storageUnitConver } from "@/utils/tools";
import BasicProcess from "@/components/BasicProcess.vue";
import LoginRegisterModal from "@/components/login/LoginRegisterModal.vue";

export default {
  // 组件逻辑和事件处理
  name: "home",
  components: {
    BasicProcess,
    LoginRegisterModal,
  },
  props: {},
  data() {
    return {
      // 四个正方形的数据
      squareItems: [
        {
          color: "#F7C9A5",
          title: "云端格式检测",
          content: `根据不同学校格式要求，多个检测项准确检测。智能分析论文结构、检测发现错误并批注产生分析报告，可大大提高学生毕业论文质量，提高指导教师工作效率及学生论文撰写效率。`,
        },
        {
          color: "#F9A291",
          title: "云端自动排版",
          content: `可根据修改建议对论文进行自动排版，并生成包括图片、表格、题注、参考文献等元素在内的规范化论文。自动排版提高了学生论文格式调整与错误修订的效率，让学生有更多的精力关注论文内容本身。`,
        },
        {
          color: "#BAC3EC",
          title: "上传论文模板2000+",
          content: `针对各高校及科研院所论文格式的特定要求，建立相应的论文格式库，生成检测模板。丰富的模板资源，可实现论文格式的精准检测。`,
        },
        {
          color: "#99DCB1",
          title: "本地格式检测/排版",
          content: `为保护论文中未公开的研究成果及涉密信息的安全，下载本插件即可实现零上传、零信息泄露，有效的保护您的研究成果及个人隐私。`,
          btnText: "下载本地插件",
        },
      ],
      sponsorList: [
        // smp 测试

        {
          img: "1hmzd.png",
          text: "汉马智档",
        },
        {
          img: "1zbj.png",
          text: "甄便捷",
        },
        {
          img: "1yxs.png",
          text: "元学术",
        },
        {
          img: "1hmzd.png",
          text: "汉马智档",
        },
        {
          img: "1zbj.png",
          text: "甄便捷",
        },
        {
          img: "1yxs.png",
          text: "元学术",
        },
        {
          img: "1hmzd.png",
          text: "汉马智档",
        },
        {
          img: "1zbj.png",
          text: "甄便捷",
        },
        {
          img: "1yxs.png",
          text: "元学术",
        },
        {
          img: "1hmzd.png",
          text: "汉马智档",
        },
        {
          img: "1zbj.png",
          text: "甄便捷",
        },
        {
          img: "1yxs.png",
          text: "元学术",
        },
        {
          img: "1zbj.png",
          text: "甄便捷",
        },
        {
          img: "1yxs.png",
          text: "元学术",
        },
        // smp 测试
      ],
      // 上传状态，是否已上传论文
      uploadStatus: false,
      // 文件列表
      fileData: null,
      isBind: 'ok',
      fileList: [],
      uploadData: {},
      // 上传状态文件信息列表页
      uploadedFileList: [],
      headers: {},
      loginRegisterVisible: false,
      // 写一个计时器，模拟上传进度
      uploadTimer: null,
      // 开始检测，还是开始排版
      operateType: "detection",
      size:50,
      visible:false
    };
  },
  watch: {},
  computed: {
    // 上传地址
    uploadUrl() {
      return window._CONFIG["domainURL"] + "/identity/sys/upload";
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    disabledUpload() {
      if(this.userInfo && (this.userInfo.id || this.userInfo.userId)){
        return true;
      }else{
        return false;
      }
      // return this.userInfo && (this.userInfo.id || this.userInfo.userId);
    },
  },
  methods: {
    handleCancel3() {
      this.visible = false
      // this.close()
    },
    getFileSize(){
      let url = "/apaas/sysConfig/getFielSize"
      this.$api.getAction(url, {}).then((res) => {
            if (res.success) {
              this.size = res.result
            }else {
              this.size = 50
            }
      });
    },

    // 获取初始化数据的接口
    async getInitData() {
      let url = "";
      if (!url) return;
      let params = {};
      this.$api.getAction(url, params).then((res) => {
        if (res.success) {
        }
        if (res.code === 510) {
          this.$message.warning(res.message);
        }
      });
    },
    handleStart(file, index, type) {
      this.operateType = type;
      // 根据操作类型，开始检测 ，还是开始排版
      if (file.status != "success") {
        return;
      }
      if (!getToken()) {
        // this.$message.error("请先登录");
        this.loginRegisterVisible = true;
        return;
      }
      // 判断当前用户，是否已登录
      if (file.status == "success") {
        // this.$router.push("/paper");
        this.$router.push({name:'paper',params: {file:null,files:false}})

      }
    },
    handleClose() {
      this.uploadStatus = false;
      clearInterval(this.uploadTimer);
      this.fileData = null;
      this.uploadedFileList = [];
      this.fileList = [];
    },
    detectFile(file) {
      // Your detection logic goes here
      console.log(`Detecting file: ${file.name}`);
      // Replace the above line with your actual detection code
    },
    // 点击正方形
    handleSquareClick(item) {
      console.log(item);
    },
    dd() {
      this.visible=true;
      // this.$message.success("建设中，敬请期待......")
    },
    handleUploadClick() {
      if (getToken()) {
        // 这里就不再选择文件
        this.$router.push({name:'paper',params: {file:"",files:true}})
        // this.$router.push("/paper");
        return;
      }
    },
    // 上传中
    statusProcess(file, perVal) {
      this.uploadedFileList.forEach((item, index) => {
        if (item.fileUid == file.uid) {
          switch (file.status) {
            case "uploading":
              item.status = "uploading";
              break;
            default:
              break;
          }
          if (perVal != null) {
            if (perVal == 100 && file.status != "success") {
              perVal = 99;
            }
          } else {
            if (file.status == "success") {
              perVal = 100;
            } else {
              perVal = 0;
            }
          }
          item.progress = perVal;
        }
      });
      if (!this.uploadedFileList.filter((file) => file.status === "ready" || file.status === "uploading").length > 0) {
        this.loading = false;
      }
    },
    changeValType(val) {},

    handleChangeFile(file, fileList) {
      var files = null
      if (file.dataTransfer && file.dataTransfer.files.length) {
        // 遍历所有文件
        for (let i = 0; i < file.dataTransfer.files.length; i++) {
          files = file.dataTransfer.files[i];
          // 在这里处理文件，例如调用另一个方法来处理文件
          console.log(files)
        }
      }
      // 获取初始化数据
      if (getToken()) {
        if(this.isBind == 'login'){
          this.$router.push({name:'paper',params: {file:null,files:false}})
          return false
        }
        if(this.isBind != 'bind'){
          if(fileList){
            this.$router.push({name:'paper',params: {file:file,files:true}})

          }else {
            files.uid = new Date().getTime();
            const isType = files.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.type === "application/msword";
            if (!isType) {
              this.$message.error("仅支持doc、docx格式文件");
              return false
            }
            var fileNew = {
              name: files.name, // 文件名
              percentage: 0, // 假设初始百分比为0，可以根据需要设置
              raw: files, // 将原始 File 对象保存为 raw 属性
              size: files.size, // 文件大小（字节）
              status: "ready", // 假设初始状态为 ready，可以根据需要设置
              uid: new Date().getTime(), // 生成一个随机的 uid
            };
            this.$router.push({name:'paper',params: {file:fileNew,files:true}})

          }
          // this.$router.push("/paper");
          return
        }
        // this.getInitData();
      }
      console.log(file, fileList)
      const isType = file.raw.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.raw.type === "application/msword";
      const isLt50M = file.raw.size / 1024 / 1024 < this.size;
      if (!isType) {
        this.$message.error("仅支持doc、docx格式文件");
        return false
      }
      if (!isLt50M) {
        this.$message.error("文件大小不能超过"+this.size+"MB");
        return false
      }

      this.fileList = fileList;
      this.fileData = file;

      if (this.fileData) {
        let fileSize = storageUnitConver(Number(file.size || 0));
        this.uploadedFileList = [];
        this.uploadedFileList.push({
          fileName: file.name,
          fileSize,
          size: file.size,
          status: file.status,
          fileUid: file.uid,
          progress: 0,
          details: "",
          inProgress: true,
        });
        this.fileData = file;
        this.startUploadTimer();
        this.uploadStatus = true;
      }
    },
    // 写一个计时器，模拟上传进度
    startUploadTimer() {
      this.uploadTimer = setInterval(() => {
        if (this.uploadedFileList.length > 0) {
          this.uploadedFileList.forEach((item, index) => {
            if (item.progress < 100) {
              item.progress += 20;
            }
            if (item.progress >= 100) {
              item.progress = 100;
              item.status = "success";
              clearInterval(this.uploadTimer);
            }
          });
        }
      }, 1000);
    },
    onProgress(event, file, fileList) {
      console.log("onProgress");
      console.log(event);
      const perVal = Math.floor(event.percent);
      // let perVal = Math.floor(file.percentage)
      if (file.percentage == 0 && file.status == "ready") {
        // let fileSize = storageUnitConver(Number(file.size || 0));
        // this.uploadedFileList.push({
        //   fileName: file.name,
        //   fileSize,
        //   size: file.size,
        //   status: file.status,
        //   fileUid: file.uid,
        //   progress: 0,
        //   details: "",
        //   inProgress: true,
        // });
        // this.fileData = file;
      } else {
        this.statusProcess(file, perVal);
        // this.statusProcess(file, null);
      }
    },
    uploadSuccess(response, file, fileList) {
      const object = this.uploadedFileList.find((d) => d.fileUid === file.uid);
      object.details = response.msg;
      console.log(file);
      console.log("uploadSuccess");
      console.log(response);
      switch (response.code) {
        case 200:
          object.status = "success";
          object.progress = 100;
          this.paperAdd(file.name, response.message);
          break;
        case "0000":
          object.status = "fail";
          break;
        default:
          break;
      }
    },
    uploadError(error, file, fileList) {
      this.uploadedFileList = [];
      this.uploadStatus = false;
    },
    beforeUpload(file) {
      const isType = file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "application/msword";
      const isLt50M = file.size / 1024 / 1024 < this.size;
      if (!isType) {
        this.$message.error("仅支持doc、docx格式文件");
      }
      if (!isLt50M) {
        this.$message.error("文件大小不能超过"+this.size+"MB");
      }
      return isType && isLt50M;
    },
    loginSuccess() {
      if (!getToken()) {

        return false
      }
        if (this.fileData) {
          if (this.$refs.upload) {
            this.isBind = 'login'
            this.$refs.upload.submit();
          } else {
            this.$router.push({name: "home"}).catch((err) => {
            });
          }
        } else {
          this.$router.push({name: "home"}).catch((err) => {
          });
        }
      // if (this.uploadStatus == false) {
      //   if (this.$refs.upload) {
      //     this.$refs.upload.submit();
      //   }
      //   setTimeout(() => {
      //     this.uploadStatus = true;
      //   }, 200);
      //   return;
      // }
    },
    loginSuccessNew() {
      if (!getToken()) {
        return false
      }
        if (this.fileData) {
          if (this.$refs.upload) {
            this.isBind = 'bind'
            this.$refs.upload.submit();
          } else {
            this.$router.push({name: "home"}).catch((err) => {
            });
          }
        } else {
          this.$router.push({name: "home"}).catch((err) => {
          });
        }

    },

    // 上传成功之后，调用新增的方法
    // CodeMap.put("CU_TDS_THESIS_ORDER_ADD"""); //新增
    // 新增论文
    async paperAdd(name, message) {
      let pendingOrders = this.$store.state.vip.vipInfo.pendingOrders;
      console.log(pendingOrders);
      let httpurl2 = '/apaas/sysConfig/getDictNew'
      let formData2 = { dicttable: 'Max_Pending_Payment_Orders,config-center' }
      let res = await this.$api.getAction(httpurl2,formData2);
      console.log("MaxPendingPaymentOrders:"+res.result[0].VALUE.MaxPendingPaymentOrders)
      let MaxPendingPaymentOrders=res.result[0].VALUE.MaxPendingPaymentOrders;
      if (pendingOrders >= MaxPendingPaymentOrders) {
        this.$message.warning("您有" + pendingOrders + "个未完成的订单，请耐心等待！");
        return;
      }
      let httpurl = "/detection/detection/add";
      //  01 待检测  02 待排版
      let status = "01";
      if (this.operateType == "layout") status = "02";
      let formData = {
        thesisName: name,
        thesisUrl: message,
        status,
        orderType: status,
      };
      const that = this;
      let method = "post";
      this.$store.dispatch("SetCode", "CU_TDS_THESIS_ORDER_ADD");
      this.$api
        .httpAction(httpurl, formData, method)
        .then((res) => {
          if (res.success) {
            // this.$message.success(res.message);
            if(this.isBind != 'bind'){
              this.$emit("ok");
              setTimeout(() => {
                // 在这里执行你想要的操作
                // that.$router.push("/paper");
                that.$router.push({name:'paper',params: {file:null,files:false}})
              }, 2000);
            }

          } else {
            this.$message.warning(res.message);
          }
        })
        .catch((err) => {
          this.$message.warning(err.message);
        });
    },
  },
  created() {
    // 获取初始化数据
    if (getToken()) {
      this.getInitData();
    }
    this.getFileSize();
    this.headers = { "Hummer-Auth": getToken() };
  },
  // 组件销毁时，清除定时器
  beforeDestroy() {
    clearInterval(this.uploadTimer);
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  margin: 0 auto;
  width: 100%;
  //max-height: calc(100vh - 160px);
  padding: 20px;
  text-align: center;
  color: #0e1111;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  h1 {
    margin-top: 84px;
    font-size: 46px;
    font-weight: normal;
  }
  h2 {
    font-size: 18px;
    margin: 16px;
    font-weight: normal;
  }
  p {
    margin-top: 12px;
    font-size: 18px;
  }
}

.square-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 90px auto 30px;
  width: 1200px;
  gap: 40px;
  .square {
    position: relative;
    height: 300px;
    background: #fff;
    flex: 1;
    border-radius: 5px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // cursor: pointer;
    .img {
      width: 40px;
      img {
        width: 100%;
      }
    }
    .title {
      font-size: 18px;
      font-weight: bolder;
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      overflow: hidden;
      color: #fff;
    }
    .content {
      position: relative;
      height: 100%;
      color: #999;
      margin: 0 16px;
      line-height: 20px;
      font-size: 14px;
      padding: 24px 0 12px;
      line-height: 22px;
      text-align: left;
      // 文字最多显示13行，超出部分隐藏，显示省略号
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 13;
      -webkit-box-orient: vertical;
      .btn {
        position: absolute;
        bottom: 25px;
        margin: auto;
        left: 0;
        right: 0;
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 20px;
        color: #3399fe;
        background: #eaf4fe;
        width: 100px;
        font-size: 14px;
        font-weight: bolder;
        border-radius: 32px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        padding: 6px 12px;
        &:hover {
          background: #d9e9fe;
        }
      }
    }
  }
}

.sponsors {
  width: 1200px;
  padding: 20px 0;
  margin: 90px auto;
  height: 384px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);

  .title {
    margin-top: 6px;
    font-size: 24px;
    font-weight: normal;
    color: #0f0f0f;
  }

  .sponsor-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto;
    min-height: 282px;
    margin: 38px 32px;
    gap: 12px;

    .sponsor-item {
      display: flex;
      align-items: center;
      width: calc(100% / 6 - 12px);
      height: 80px;
      border-radius: 8px;
      // 边框阴影
      // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      // box-shadow: 0 2px 6px 0 #f8f9fb;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
      background: #fff;

      .img {
        width: 60px;
        margin: 0 12px;
        img {
          width: 100%;
        }
      }
      .text {
        font-size: 18px;
        color: #0f0f0f;
        font-weight: bolder;
      }
    }
  }
}

.shine-box {
  margin: auto;
  border-radius: 10px;
  text-align: center;
  cursor: default;
  &:hover {
    .shine-span {
      background: #0e1111 linear-gradient(to left, transparent, #fff, transparent) no-repeat 0 0;
      background-size: 20% 100%;
      background-position: 0 0;
      height: 100%;
      width: 100%;
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      animation: shine 4s infinite;
    }
  }
}

@keyframes shine {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 100% 100%;
  }
}
// 修改组件样式
/deep/.el-upload {
  width: 100%;
  height: 100%;
}
/deep/.el-upload-dragger {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 1px dashed #409eff;
  .el-upload__text {
    font-size: 14px;
    text-align: center;
    margin-top: 12px;
    line-height: 24px;
    margin-bottom: 4px;
    height: 48px;
  }
}
/deep/.el-upload__tip {
  font-size: 14px;
  margin-top: -40px;
}
/* 样式定制 */
.upload-demo {
  // border: 1px dashed #409eff;
  border-radius: 6px;
  text-align: center;
  color: #666;
  width: 986px;
  margin: 80px auto;
  height: 252px;
  .upload-img {
    width: 76px;
    margin-top: 26px;
  }
}

.uploaded-box {
  position: relative;
  border: 1px dashed #409eff;
  border-radius: 6px;
  text-align: center;
  color: #666;
  width: 986px;
  margin: 80px auto;
  min-height: 252px;

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  .file-list {
    font-family: Arial, sans-serif;
    margin: 70px 20px;
    min-height: 110px;
    .header {
      display: flex;
      font-weight: bold;
      padding: 10px;
      height: 24px;
      background-color: #eaf4fe;
      // background-color: #f0f0f0;
      & > div {
        flex: 2;
      }
      .min-width {
        flex: 1;
      }
    }

    .body {
      // display: flex;
      // align-items: center;
      background: #fff;
      .file-item {
        display: flex;
        align-items: center;
        padding: 10px;
        height: 54px;
        & > div {
          flex: 2;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .min-width {
          flex: 1;
        }

        .progress-box {
          display: flex;
          justify-content: center;
          .progress-inner {
            width: 60%;

            .progress-text {
              display: flex;
              justify-content: center;
              align-items: center;
              .img {
                width: 24px;
                margin-right: 6px;
                img {
                  width: 100%;
                }
              }
              .name {
              }
            }
          }
        }
      }

      .flex {
        display: flex;
        justify-content: center;
      }

      .btn {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 20px;
        color: #3399fe;
        background: #eaf4fe;
        width: 100px;
        font-size: 14px;
        font-weight: bolder;
        border-radius: 32px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        padding: 6px 12px;
        display: flex;
        align-items: center;
        &:hover {
          background: #d9e9fe;
        }
        .btn-img {
          width: 28px;
        }
        img {
          margin-right: 12px;
        }
      }

      .disabled-btn {
        cursor: not-allowed;
        background: #eaf4fe;
        color: #999;
        &:hover {
          background: #eaf4fe;
        }
      }
      .progress {
        width: 100%;
      }
    }
  }
}
</style>
