const resource = {
  state: {
    code: '',
  },
  mutations: {
    SET_CODE: (state, code) => {
      state.code = code
    },
  },
  actions: {
    SetCode({ commit }, code) {
      commit('SET_CODE', code)
    },
  },
}
export default resource
