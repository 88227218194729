import { getAction, httpAction } from "@/http/interface";
const vip = {
  state: {
    vipInfo: {},
  },
  mutations: {
    SET_VIP_INFO: (state, vipInfo) => {
      state.vipInfo = vipInfo;
    },
    CHANGE_SETTING: (state, { key, value }) => {
      if (state.hasOwnProperty(key)) {
        state[key] = value;
      }
    },
  },
  actions: {
    changeSetting({ commit }, data) {
      commit("CHANGE_SETTING", data);
    },
    getVipList({ commit }, info) {
      return new Promise((resolve, reject) => {
        getAction("/detection/detection/listVip", {})
          .then((response) => {
            if (response.success) {
              const result = response.result;
              console.log(result);
              commit("SET_VIP_INFO", result);
              resolve(response);
            } else {
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUserInfonew({ commit }, info) {
      return new Promise((resolve, reject) => {
        getAction("/identity/user/getUser", {})
            .then((response) => {
              if (response.success) {
                const result = response.result;
                console.log(result);
                commit("SET_INFO", result);
                resolve(response);
              } else {
                resolve(response);
              }
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
    getUserInfoByUserId({ commit }, info) {
      return new Promise((resolve, reject) => {
        console.log(this.state)
        console.log(this.state.user.info)
        getAction("/identity/user/getUser", {type:'type'})
            .then((response) => {
              if (response.success) {
                const result = response.result;
                console.log(result);
                commit("SET_INFO", result);
                resolve(response);
              } else {
                resolve(response);
              }
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
  },
};
export default vip;
