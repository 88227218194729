<template>
  <el-dialog :append-to-body='true'  width="500px" :visible.sync="visible" :title="null" class="none-title" :before-close="handleClose" :modal="modal">
    <template v-if="currentPage == 'login'">
      <LoginComponent
          ref="loginRef"
        @itemClickForgotPassword="itemClickForgotPassword"
        @itemClickRegister="itemClickRegister"
        @itemClickBackLogin="itemClickBackLogin"
        :key="componentKey"
        @ok="handleOk"
        @okNew="handleOkNew"
          :types="types"
      />
    </template>
    <template v-if="currentPage == 'retrieve'">
      <RetrieveComponent
        @itemClickForgotPassword="itemClickForgotPassword"
        @itemClickRegister="itemClickRegister"
        @itemClickBackLogin="itemClickBackLogin"
        :key="componentKey"
        @ok="handleOk"
      />
    </template>
    <template v-if="currentPage == 'register'">
      <RegisterModal
          @itemClickForgotPassword="itemClickForgotPassword"
          @itemClickRegister="itemClickRegister"
          @itemClickBackLogin="itemClickBackLogin"
          :key="componentKey"
          @ok="handleOk"
      />
    </template>
  </el-dialog>
</template>

<script>
import LoginComponent from "./LoginComponent.vue";
import RetrieveComponent from "./RetrieveComponent.vue";
import RegisterModal from "./RegisterModal.vue";

export default {
  name: "LoginRegisterModal",
  components: {
    LoginComponent,
    RegisterModal,
    RetrieveComponent,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      types: "account",
      activeTab: "account",
      showQRCode: false,
      // 当前页面，是登录还是找回密码
      currentPage: "login",
      componentKey: new Date().getTime(),
    };
  },
  watch: {
    visible(){
      this.types="account"
    }
  },
  methods: {
    login() {
      // 处理登录逻辑
    },
    itemClickForgotPassword() {
      // 显示找回密码弹框
      this.currentPage = "retrieve";
    },
    itemClickRegister() {
      // 跳转到注册页面
      // this.$router.push({ name: "register" });
      this.currentPage ="register"
    },
    itemClickBackLogin(type) {
      // 返回登录页面
      this.currentPage = "login";
      console.log(type)
      if(type){
        this.types = type
      }
    },
    handleOk() {
      this.handleClose();
      this.$emit("ok");
    },
    handleOkNew() {
      this.$emit("okNew");
    },
    handleClose() {
      // 关闭弹框
      this.$emit("update:visible", false);
      this.currentPage = "login";
      this.componentKey = new Date().getTime();
    },
  },
};
</script>

<style lang="scss" scoped>
/* 样式可以根据你的需求进行自定义 */
</style>
