// 引入配置路由的相关信息
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import Home from "@/views/home/Index";
import Compose from "@/views/compose/Index";
import Me from "@/views/me/Index";
import Payment from "@/views/payment/Index";
import HomeLayout from "@/layout/Layout";
import UiaLayout from "@/views/uia/Layout";

import { getToken, setToken, removeToken } from "@/utils/token";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeLayout,
    // redirect: "/home",
    meta: {
      title: "首页",
    },
    children: [
      {
        // path: "/home",
        path: "/",
        component: Home,
        name: "home",
        meta: {
          title: "首页",
        },
      },
      // 我的论文
      {
        path: "/paper",
        component: () => import(/* webpackChunkName: "paper" */ "@/views/paper/Index"),
        name: "paper",
        meta: {
          // 是否需要验证
          requireAuth: true,
          title: "我的论文",
        },
      },
      // 模板设置
      {
        path: "/template",
        component: () => import(/* webpackChunkName: "template" */ "@/views/template/Index"),
        name: "template",
        meta: {
          // 是否需要验证
          requireAuth: true,
          title: "模板设置",
        },
      },
      {
        path: "/compose",
        component: Compose,
        name: "compose",
        meta: {
          // 是否需要验证
          // requireAuth: true,
          title: "排版首页",
        },
      },
      {
        path: "/me",
        component: Me,
        name: "me",
        meta: {
          // 是否需要验证
          requireAuth: true,
          title: "个人中心",
        },
        children: [
          {
            path: "PersonalInformation",
            id: "personal",
            key: "personal",
            title: "个人信息",
            component: () => import(/* webpackChunkName: "uia" */ `@/views/me/PersonalInformation`),
            parentId: "settings",
          },
          {
            path: "AccountSecurity",
            id: "security",
            key: "security",
            title: "账户安全",
            component: () => import(/* webpackChunkName: "uia" */ `@/views/me/AccountSecurity`),
            parentId: "settings",
          },
        ],
      },
      {
        // 账户设置
        path: "/payment",
        component: Payment,
        name: "payment",
        meta: {
          // 是否需要验证
          requireAuth: true,
          title: "我的资产",
        },
        children: [
          // MyFreeCard
          {
            path: "MyFreeCard",
            id: "freeCard",
            key: "freeCard",
            title: "我的免费卡",
            component: () => import(/* webpackChunkName: "uia" */ `@/views/payment/MyFreeCard`),
            parentId: "payment",
          },
          // MyVipCard
          {
            path: "MyVipCard",
            id: "vipCard",
            key: "vipCard",
            title: "我的会员卡",
            component: () => import(/* webpackChunkName: "uia" */ `@/views/payment/MyVipCard`),
            parentId: "payment",
          },
          // MyCashback
          {
            path: "MyCashback",
            id: "cashback",
            key: "cashback",
            title: "我的返现",
            component: () => import(/* webpackChunkName: "uia" */ `@/views/payment/MyCashback`),
            parentId: "payment",
          },
          // MyCoupon
          {
            path: "MyCoupon",
            id: "coupon",
            key: "coupon",
            title: "我的优惠券",
            component: () => import(/* webpackChunkName: "uia" */ `@/views/payment/MyCoupon`),
            parentId: "payment",
          },
        ],
      }
    ],
  },
  // 帮助中心
  {
    path: "/help",
    name: "help",
    component: () => import(/* webpackChunkName: "uia" */ "@/views/help/Index"),
  },
  {
    path: "/user",
    // component: UserLayout,
    component: UiaLayout,
    redirect: "/user/login",
    children: [
      {
        path: "login",
        name: "login",
        component: () => import(/* webpackChunkName: "uia" */ "@/views/uia/Login"),
        meta: {
          title: "登录",
        },
      },
      {
        path: "retrieve",
        name: "retrieve",
        component: () => import(/* webpackChunkName: "uia" */ "@/views/uia/Retrieve"),
      },
      // 注册
      {
        path: "register",
        name: "register",
        component: () => import(/* webpackChunkName: "uia" */ "@/views/uia/Register"),
      },
      // 注册扫码的回调页面
      {
        path: "registerCallback",
        name: "registerCallback",
        component: () => import(/* webpackChunkName: "uia" */ "@/views/uia/RegisterCallback"),
      },
    ],
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "NotFound" */ "@/views/404/index.vue"),
  },
];

// 创建路由对象
const router = new VueRouter({
  // mode: "history",
  mode: "hash",
  // base: "/paper",
  routes,
});

// 如果路由需要登录验证，并且用户没有登录（没有token），则跳转到登录页
router.beforeEach((to, from, next) => {
  const token = getToken();
  // 如果路由需要登录验证，并且用户没有登录（没有token），则跳转到登录页
  if (to.meta.requireAuth && !token) {
    next("/user/login");
  } else {
    next();
  }
});

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

// 将 router 对象导出
export default router;
