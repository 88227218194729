<template>
  <div class="uia-layout">
    <div class="title" :class="{ 'light-title': !isShowSide }">
      <div class="label">
        <img src="~@/assets/img/uia/logo.png" v-if="isShowSide" alt="" />
        <img src="~@/assets/img/uia/logo-black.png" v-if="!isShowSide" alt="" />
      </div>
    </div>
    <div class="center-container">
      <div class="side" v-if="isShowSide">
        <div class="img">
          <img class="img" src="~@/assets/img/uia/side.jpg" alt="" />
        </div>
      </div>
      <div class="login-border" :class="{ 'max-border': !isShowSide }">
        <router-view />
      </div>
    </div>
    <div class="footer">
      {{ footerText }} 湖南汉马科技有限公司 版权所有 <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备19027918号-1</a>&nbsp;服务热线：400 999 1468
    </div>
  </div>
</template>

<script>
export default {
  name: "Layout",
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    // 是否显示侧边栏
    isShowSide() {
      let flag = true;
      let path = this.$route.path;
      let hideSidePath = ["/help", "/register", "/user/register", "/user/registerCallback"];
      if (hideSidePath.includes(path)) {
        flag = false;
      }
      return flag;
    },
    footerText() {
      let year = new Date().getFullYear();
      return `©2012-${year}`;
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
// 定义一个主题色的变量
.uia-layout {
  background-repeat: no-repeat;
  background-size: 100% 99.99%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: #333;
  text-align: left;
  font-size: 13px;
  .title {
    width: 100%;
    height: 60px;
    position: relative;
    background: #48525c;
    .label {
      height: 100%;
      img {
        max-height: 100%;
        margin-left: 36px;
        padding: 4px;
      }
    }
  }
  .light-title {
    background: #fff;
    // box-shadow 实现底部阴影
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  .center-container {
    height: calc(100% - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow 实现底部阴影
    // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #e8e8e8;
    .side {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      .img {
        width: 460px;
        height: 540px;

        // margin-left: 20px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .login-border {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .login-weaper {
        width: 400px;
        height: 400px;
        background-color: #fff;
        border-radius: 10px;
        .login-left {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .login-form {
            width: 300px;
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .login-title {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              font-weight: bold;
            }
            .login-input {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              .ant-input {
                width: 200px;
                height: 30px;
              }
            }
            .login-button {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              .ant-btn {
                width: 200px;
                height: 30px;
              }
            }
          }
        }
      }
    }
    .max-border {
      width: 100%;
    }
  }
  .footer {
    color: #6c6c6c;
    background: #f7f8fa;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      color: #3399fe;
    }
  }
}
</style>
