<template>
  <div class="layout-main">
    <home-header :class="{ 'base-box-fixed': isFixed }" />
    <home-main />
  </div>
</template>

<script>
import { HomeHeader, HomeMain, HomeFooter } from "./components";
export default {
  name: "",
  components: {
    HomeHeader,
    HomeMain,
    HomeFooter,
  },
  props: {},
  data() {
    return {
      isFixed: false,
      timer: null,
      show: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    //头部fixed定位
    showSearch() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 80) {
        // 当页面滚动到高度 30 px处，动态绑定class 来设置头部固定定位
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  },
  created() {},
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.showSearch);
    // 监听滚动事件 base-box 的样式设置 fixed
    // console.log(this.$refs.baseSelf);
    // console.log(this.$refs.baseSelf.offsetTop);
    // let offsetTop = this.$refs.baseSelf.offsetTop;
    // window.addEventListener(
    //   "scroll",
    //   (e) => {
    //     console.log(e);
    //     console.log(e.target);
    //     let scrollTop = e.target.scrollTop;
    //     console.log("scrollTop");
    //     console.log(scrollTop);
    //     if (this.timer) {
    //       clearTimeout(this.timer);
    //     }
    //     this.timer = setTimeout(() => {
    //       //滚动距离为包裹层距离内容层的高度
    //       if (scrollTop >= offsetTop) {
    //         this.isFixed = true;
    //       } else {
    //         this.isFixed = false;
    //       }
    //     }, 10);
    //   },
    //   true
    // );
  },
  // 组件销毁时，移除事件监听
  beforeDestroy() {
    window.removeEventListener("scroll", this.showSearch);
  },
};
</script>
<style lang="scss" scoped>
.base-box-fixed {
  box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
  background: #fff;
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
  transition: 0.2s ease-in;
  animation-duration: 0.5s;
  animation-name: slideInDown;
}
@keyframes slideInDown {
  0% {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.layout-main {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  // height: 100%;
  border: hidden;
  overflow: hidden;
}

@media (max-width: 1080px) {
  .app-main {
    width: 100%;
    height: 100%;
    background-position: bottom left;
    background-size: auto;
  }
}
</style>
