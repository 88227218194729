<template>
  <div class="login-wrapper">
    <div class="login-box">
      <div class="title">
        <div class="tabs">
          <div class="tab tab-line">{{ stepName }}</div>
        </div>
      </div>
      <div class="form">
        <el-form :model="form" :rules="validatorRules" ref="form" style="width: 100%" label-width="0" @keyup.enter.native="handleSubmit()">
          <template v-if="currentTab == '0'">
            <el-form-item prop="mobile">
              <el-input v-model="form.mobile" size="large" type="text" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-row :gutter="8">
                <el-col :span="12">
                  <el-input v-model="form.code" type="text" placeholder="请输入手机验证码"></el-input>
                </el-col>
                <el-col :span="12">
                  <el-button class="default-button" :disabled="state.smsSendBtn" @click.stop.prevent="getCaptcha" :loading="loading">
                    {{ !state.smsSendBtn ? "获取手机验证码" : state.time + " s 后重新获取" }}
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-button size="large" type="primary" class="submit-button" :loading="loading" @click.stop.prevent="nextStep"> 下一步 </el-button>
            </el-form-item>
          </template>
          <template v-if="currentTab == '1'">
            <el-form-item prop="password">
              <password-input v-model="form.password" autocomplete="false" placeholder="请输入8-20位新密码" />
            </el-form-item>
            <el-form-item prop="password">
            </el-form-item>
            <el-form-item prop="confirmPassword">
              <password-input v-model="form.confirmPassword" autocomplete="false" placeholder="请再次确认密码" />
            </el-form-item>
            <el-form-item>
              <el-button size="large" type="primary" class="submit-button" :loading="loading" @click="handleSubmit"> 确定并重置 </el-button>
            </el-form-item>
          </template>
          <template v-if="currentTab == '2'">
            <div class="result-container">
              <div class="logo">
                <img src="~@/assets/img/uia/complete.png" alt="" />
              </div>
              <div class="text">密码重置成功</div>
            </div>
            <el-button size="large" type="primary" class="submit-button" @click="itemClickBackLogin" v-if="currentTab == '2'"> 立即登录 </el-button>
          </template>
        </el-form>
      </div>
    </div>
    <!-- 忘记密码和立即注册 -->
    <div class="operate-options">
      <div class="item"></div>
      <div class="item">
        <div @click="itemClickBackLogin" v-if="currentTab != '2'">返回登录</div>
      </div>
      <div class="item"></div>
    </div>
  </div>
</template>

<script>
import PasswordInput from "@/components/PasswordInput/index.vue";
import { RetrieveMixin } from "@/mixins/RetrieveMixin";
export default {
  name: "Retrieve",
  mixins: [RetrieveMixin],
  components: {
    PasswordInput,
  },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    backLogin() {
      this.$emit("backLogin");
    },
    itemClickBackLogin() {
      this.$emit("itemClickBackLogin");
    },
  },
};
</script>
<style lang="scss" scoped>
$primary-color: #3399ff;
.operate-options,
.other-login {
  margin-top: 10px;
  text-align: center;
}

.operate-options {
  display: flex;
  justify-content: space-between;
  color: #3399fe;
  & > div {
    cursor: pointer;
  }
}
.login-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // 微软雅黑字体
  font-family: "Microsoft YaHei";
  color: #000;
  .login-box {
    // width: 390px;
    width: 100%;
    .title {
      position: relative;
      width: 100%;
      height: 50px;
      display: flex;
      font-size: 22px;
      .tabs {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .tab {
          width: 50%;
          height: 50px;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
          border-bottom: 2px solid #e5e5e5;
          &.active {
            color: $primary-color;
            border-bottom: 2px solid $primary-color;
          }
        }
        .tab-line {
          width: 100%;
        }
      }
      .label {
        position: absolute;
        right: 0;
        bottom: 8px;
        width: 50px;
        height: 50px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
    .form {
      width: 100%;
      // height: 280px;
      height: 270px;
      padding-top: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    .links {
      height: 60px;
      margin-bottom: 10px;
      display: flex;
      .item {
        position: relative;
        flex: 1;
        cursor: pointer;
        height: 60px;
        line-height: 60px;
        text-align: center;
        margin: 4px 0;
        border-top: 1px dashed #e8e8e8;
        border-bottom: 1px dashed #e8e8e8;
        &:hover {
          color: $primary-color;
        }
        // 每个 直接间隔 使用伪元素
        &::after {
          content: "";
          width: 1px;
          height: 20px;
          background-color: #e8e8e8;
          margin: 10px 0;
          position: absolute;
          right: 0;
          margin: auto;
          top: 0;
          bottom: 0;
        }
        // 最后一个
        &:last-child::after {
          display: none;
        }
      }
    }
    .help {
      height: 70px;
      .title {
        color: #333;
        margin-top: 14px;
        font-size: 14px;
        height: 20px;
      }
      .content {
        color: #b5b5b5;
        margin-top: 12px;
        .link {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}
.code-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid lightgray;
  // border-radius: 5px;
  border-radius: 0;
}

.submit-button {
  width: 100%;
  height: 46px;
  border-radius: 0;
  background: $primary-color;
  color: #fff;
  cursor: pointer;
  &:hover {
    background: darken($primary-color, 10%);
  }
  &:focus {
    background: $primary-color !important;
  }
}

.default-button {
  width: 100%;
  height: 46px;
  border-radius: 0;
  background: #fff;
  border: 1px solid $primary-color;
  color: $primary-color;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}
.default-button:disabled {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
  cursor: not-allowed;
}

/deep/.el-input__inner {
  height: 46px;
  border-radius: 0;
}
/deep/.el-button {
  height: 46px;
  border-radius: 0 !important;
}

/deep/.el-form-item {
  margin-bottom: 10px;
}

/deep/.el-form-item__error {
  position: relative;
  min-height: 12px;
}

.result-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 48px 0;
  .logo {
    width: 48px;
    height: 48px;
    img {
      width: 100%;
    }
  }
  .text {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
  }
}
</style>
