<template>
  <div class="login-wrapper">
    <div class="login-box">
      <div class="title">
        <div class="tabs">
          <div class="tab tab-line">{{ '手机注册' }}</div>
        </div>
      </div>
      <div>
        <div class="form">
        <el-form
            :model="form"
            class="user-layout-login"
            ref="formLogin"
            style="width: 100%"
            :rules="validatorRules"
            @keyup.enter.native="handleSubmit()"
        >
          <el-form-item prop="mobile">
            <el-input v-model="form.mobile" size="large" type="text" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-row :gutter="16">
            <el-col class="gutter-row" :span="12">
              <el-form-item prop="captcha">
                <el-input v-model="form.captcha" size="large" type="text" placeholder="请输入手机验证码"></el-input>
              </el-form-item>
            </el-col>
            <el-col class="gutter-row" :span="12" style="padding-left: 0px">
              <el-button class="default-button" :disabled="state.smsSendBtn" @click.stop.prevent="getCaptcha" :loading="state.smsSendBtn">
                {{ !state.smsSendBtn ? "获取手机验证码" : state.time + " s 后重新获取" }}
              </el-button>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button
                size="large"
                type="primary"
                html-type="submit"
                class="submit-button"
                :loading="loginBtn"
                style="height: 46px; width: 100%"
                @click.stop.prevent="handleSubmit"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
        </div>
      </div>
    </div>
    <!-- 忘记密码和立即注册 -->
    <div class="operate-options">
      <div class="item"></div>
      <div class="item">
        <div @click="itemClickBackLogin" v-if="currentTab != '2'">已有账号，直接登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { RetrieveMixin } from "@/mixins/RetrieveMixin";
import { setToken } from "@/utils/token";
export default {
  name: "Register",
  mixins: [RetrieveMixin],
  props: {
    // 注册扫码回调页面
    registerCallback: {
      type: Boolean,
      default: false,
    },
  },
  components: {
  },
  data() {
    return {
      // 当前步骤 1:完善手机信息 2:关联微信 3:完成账号注册
      currentStep: 1,
      stepList: [
        {
          stepNum: 1,
          stepText: "完善手机信息",
        },
        {
          stepNum: 2,
          stepText: "关联微信",
        },
        {
          stepNum: 3,
          stepText: "完成账号注册",
        },
      ],
      loginBtn: false,
      phoneNumber: "",
      verificationCode: "",
      // 倒计时
      state: {
        time: 60,
        interval: null,
        smsSendBtn: false,
      },
      // 表单数据
      form: {
        username: "",
        password: "",
        mobile: "",
        captcha: "",
        inputCode: "",
      },
      // 表单验证规则
      validatorRules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: this.validateMobile, trigger: "blur" },
        ],
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        inputCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  methods: {
    itemClickBackLogin() {
      this.$emit("itemClickBackLogin",'account');
    },
    // 校验手机号码
    validateMobile(rule, value, callback) {
      if (!value || new RegExp(/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/).test(value)) {
        callback();
      } else {
        callback("您的手机号码格式不正确");
      }
    },
    // 获取验证码
    getCaptcha(e) {
      e.preventDefault();
      // 对单个表单数据进行校验 validateField
      this.$refs["formLogin"].validateField("mobile", (err) => {
        console.log("validvalidvalid");
        console.log(err);
        if (err) {
          console.log("请输入手机号");
        } else if (!err) {
          const params = {
            mobile: this.form.mobile,
            smsmode: "1",
            userType:"01"
          };
          this.$api.postAction("/identity/sys/sms", params).then((res) => {
            if (res.success) {
              // 提示信息
              // this.$notify({
              //   title: "温馨提示",
              //   message: "验证码发送中",
              //   type: "success",
              // });
              this.resetCountdown();
              this.startCountdown();
            } else {
              console.log(res.message);
              // 提示信息
              this.$message.error(res.message);
              // this.$notify({
              //   title: "温馨提示",
              //   message: res.message,
              //   type: "error",
              // });
              clearInterval(this.state.interval);
              this.state.time = 0;
              this.state.smsSendBtn = false;
            }
          });
        }
      });
    },
    // 开始倒计时
    startCountdown() {
      console.log(this.state.time)
      this.state.smsSendBtn = true;
      this.state.interval = setInterval(() => {
        if (this.state.time-- <= 0) {
          this.state.time = 60;
          this.state.smsSendBtn = false;
          clearInterval(this.state.interval);
        }
      }, 1000);
    },
    // 重置倒计时
    resetCountdown() {
      clearInterval(this.state.interval);
      this.state.time = 60;
      this.state.smsSendBtn = false;
    },
    handleLogin() {
      this.$router.push({
        path: "/user/login",
        query: {
          loginType: "sms",
        },
      });
    },
    handleRegisterWeChat() {
      // smp 模拟扫码成功的回调
      if (process.env.NODE_ENV === "development") {
        this.$router.push({ name: "registerCallback" }).catch((err) => {});
        return;
      }
    },
    // 登录
    handleSubmit() {
      this.$refs["formLogin"].validate(async (valid) => {
        if (valid) {
          // 手机号验证码登录成功后的逻辑
          const result = await this.perfectPhoneInfo();
          if (result.success) {
            if (result.message && result.message.includes("成功")) {
              this.$message.success(result.message);
              // xmp 暂时不做微信绑定
              // this.currentStep = 2;
              setTimeout(() => {
                this.phoneLogin();
              }, 1500);
              // setTimeout(() => {
              //   this.$emit("itemClickBackLogin","sms");
              // }, 1500);
            } else {
              this.$message.error(result.message);
            }
          } else {
            this.$message.error(result.message);
          }
        } else {
          console.log(valid);
        }
      });
    },
    // 完善手机信息
    perfectPhoneInfo() {
      return new Promise((resolve, reject) => {
        const params = {
          mobile: this.form.mobile,
          captcha: this.form.captcha,
        };
        console.log("注册信息");
        console.log(params);
        this.$api.getAction("/identity/user/register", params).then((res) => {
          if (res.success) {
            resolve(res);
          } else {
            reject(res);
          }
        });
      });
    },
    // 手机号登录
    phoneLogin() {
      let params = {
        username: this.form.mobile,
        captcha: this.form.captcha,
        grant_type: "sms",
        userType:"01",
        userlogoType:"1"
      };
      this.$api.postAction("/identity/sys/login", params).then((res) => {
        if (res.success && res.result) {
          const result = res.result;
          const userInfo = result.userInfo;
          this.$store.dispatch("setUserInfo", userInfo);
          console.log("成功登陆");
          console.log("userInfo");
          console.log(userInfo);
          this.$router.push({ name: "home" }).catch((err) => {});
          // setLocal("userInfo", JSON.stringify(userInfo));
          // setLocal("token", result.token);
          setToken(result.token);
          this.$message.success(res.message);
          // this.$emit("itemClickBackLogin","sms");
          // this.$emit("update:visible", false);
          this.$emit("ok");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    sendVerificationCode() {
      // 实现获取验证码的逻辑，提交成功后切换到下一步
      // 例如，可以在这里调用后端接口发送验证码
      // 如果成功，切换到下一步
      // this.currentStep = 2;
    },
    nextStep() {
      // 实现切换到下一步的逻辑
      this.currentStep += 1;
    },
    skipWeChat() {
      // 实现跳过关联微信的逻辑，切换到下一步
      this.completeRegister();
    },
    // 完成注册的方法
    completeRegister() {
      // 实现完成注册的逻辑
      // 例如，可以在这里调用后端接口完成注册
      // 如果成功，切换到下一步
      this.currentStep = 3;
      setTimeout(() => {
        this.$router.push({
          path: "/user/login",
          query: {
            loginType: "sms",
          },
        });
      }, 1500);
      // setTimeout(() => {
      //   this.phoneLogin();
      // }, 1500);
    },
  },
  created() {
    if (this.registerCallback) {
      this.currentStep = 3;
    }
  },
};
</script>
<style lang="scss" scoped>
$primary-color: #3399ff;
.operate-options,
.other-login {
  margin-top: 10px;
  text-align: center;
}

.operate-options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #3399fe;
  & > div {
    cursor: pointer;
  }
}
.login-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // 微软雅黑字体
  font-family: "Microsoft YaHei";
  color: #000;
  .login-box {
    // width: 390px;
    width: 100%;
    .title {
      position: relative;
      width: 100%;
      height: 50px;
      display: flex;
      font-size: 22px;
      .tabs {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .tab {
          width: 50%;
          height: 50px;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
          border-bottom: 2px solid #e5e5e5;
          &.active {
            color: $primary-color;
            border-bottom: 2px solid $primary-color;
          }
        }
        .tab-line {
          width: 100%;
        }
      }
      .label {
        position: absolute;
        right: 0;
        bottom: 8px;
        width: 50px;
        height: 50px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
    .form {
      width: 100%;
      // height: 280px;
      height: 270px;
      padding-top: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    .links {
      height: 60px;
      margin-bottom: 10px;
      display: flex;
      .item {
        position: relative;
        flex: 1;
        cursor: pointer;
        height: 60px;
        line-height: 60px;
        text-align: center;
        margin: 4px 0;
        border-top: 1px dashed #e8e8e8;
        border-bottom: 1px dashed #e8e8e8;
        &:hover {
          color: $primary-color;
        }
        // 每个 直接间隔 使用伪元素
        &::after {
          content: "";
          width: 1px;
          height: 20px;
          background-color: #e8e8e8;
          margin: 10px 0;
          position: absolute;
          right: 0;
          margin: auto;
          top: 0;
          bottom: 0;
        }
        // 最后一个
        &:last-child::after {
          display: none;
        }
      }
    }
    .help {
      height: 70px;
      .title {
        color: #333;
        margin-top: 14px;
        font-size: 14px;
        height: 20px;
      }
      .content {
        color: #b5b5b5;
        margin-top: 12px;
        .link {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}
.code-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid lightgray;
  // border-radius: 5px;
  border-radius: 0;
}

.submit-button {
  width: 100%;
  height: 46px;
  border-radius: 0;
  background: $primary-color;
  color: #fff;
  cursor: pointer;
  &:hover {
    background: darken($primary-color, 10%);
  }
  &:focus {
    background: $primary-color !important;
  }
}

.default-button {
  width: 100%;
  height: 46px;
  border-radius: 0;
  background: #fff;
  border: 1px solid $primary-color;
  color: $primary-color;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}
.default-button:disabled {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
  cursor: not-allowed;
}

/deep/.el-input__inner {
  height: 46px;
  border-radius: 0;
}
/deep/.el-button {
  height: 46px;
  border-radius: 0 !important;
}

/deep/.el-form-item {
  margin-bottom: 10px;
}

/deep/.el-form-item__error {
  position: relative;
  min-height: 12px;
}

.result-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 48px 0;
  .logo {
    width: 48px;
    height: 48px;
    img {
      width: 100%;
    }
  }
  .text {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
  }
}
</style>
