<template>
  <div class="login-wrapper">
    <div class="login-box">
      <div class="title">
        <div class="tabs">
          <template v-if="types && types == 'scan'">
            <div class="tab tab-line">微信登录</div>
          </template>
          <template v-else-if="types && types == 'bindPhone'">
            <div class="tab tab-line">手机号绑定验证</div>
          </template>
          <template v-else>
            <div class="tab" :class="{ active: types === 'account' }" @click="switchType('account')">账号登录</div>
            <div class="tab" :class="{ active: types === 'sms' }" @click="switchType('sms')">短信登录</div>
          </template>
        </div>
        <div class="label" v-if="false">
          <img src="~@/assets/img/uia/account-login.png" @click="switchType('account')" v-if="types && types == 'scan'" alt="" />
          <img src="~@/assets/img/uia/qrcode-login.png" @click="switchType('scan')" v-else alt="" />
        </div>
      </div>
      <div class="form">
        <template v-if="types && types == 'scan'">
          <div>
            <!-- smp 微信扫码 -->
          </div>
        </template>
        <template v-else>
          <el-form
            :model="form"
            class="user-layout-login"
            ref="formLogin"
            style="width: 100%"
            :rules="validatorRules"
            @keyup.enter.native="handleSubmit()"
          >
            <template v-if="types && types == 'account'">
              <el-form-item prop="username">
                <el-input class="login-input" size="large" v-model="form.username" type="text" placeholder="请输入账号"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <password-input
                  class="login-input"
                  v-model="form.password"
                  size="large"
                  autocomplete="false"
                  placeholder="请输入密码"
                />
              </el-form-item>
              <el-row :gutter="0">
                <el-col :span="12">
                  <el-form-item prop="inputCode">
                    <el-input
                      class="login-input"
                      v-model="form.inputCode"
                      size="large"
                      type="text"
                      @change="inputCodeChange"
                      placeholder="请输入验证码"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" style="padding-right: 12px; padding-left: 0px">
                  <div style="width: 100%; height: 46px; padding-left: 10px">
                    <img v-if="requestCodeSuccess" :src="randCodeImage" class="code-image" @click="handleChangeCheckCode" />
                    <img v-else class="code-image" src="~@/assets/img/uia/checkcode.png" @click="handleChangeCheckCode" />
                  </div>
                </el-col>
              </el-row>
            </template>
            <template v-if="types && types == 'sms'">
              <el-form-item prop="mobile">
                <el-input v-model="form.mobile" size="large" type="text" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-row :gutter="16">
                <el-col class="gutter-row" :span="12">
                  <el-form-item prop="captcha">
                    <el-input v-model="form.captcha" size="large" type="text" placeholder="请输入手机验证码"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="gutter-row" :span="12" style="padding-left: 0px">
                  <el-button class="default-button" :disabled="state.smsSendBtn" @click.stop.prevent="getCaptcha" :loading="state.smsSendBtn">
                    {{ !state.smsSendBtn ? "获取手机验证码" : state.time + " s 后重新获取" }}
                  </el-button>
                </el-col>
              </el-row>
            </template>
            <template v-if="types && types == 'bindPhone'">
              <div class="tips"><span>提示：</span>为了账号安全需进行手机验证，验证成功后可直接使用手机号登录，也可使用找回密码操作。</div>
              <el-form-item prop="mobile">
                <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item prop="captcha">
                <el-row :gutter="8" style="display: flex">
                  <el-col :span="12">
                    <el-input v-model="form.captcha" type="text" placeholder="手机短信验证码"></el-input>
                  </el-col>
                  <el-col :span="12">
                    <el-button tabindex="-1" size="default" style="width: 100%" :disabled="state.smsSendBtn" @click.stop.prevent="getCaptcha">{{
                      (!state.smsSendBtn && "获取手机验证码") || state.time + " s"
                    }}</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </template>
            <el-form-item>
              <el-button
                size="large"
                type="primary"
                html-type="submit"
                class="submit-button"
                :loading="loginBtn"
                style="height: 46px; width: 100%"
                @click.stop.prevent="handleSubmit"
              >
                {{ types && types == "bindPhone" ? "绑定验证" : "登录" }}
              </el-button>
            </el-form-item>
          </el-form>
        </template>
      </div>
      <!-- 忘记密码和立即注册 -->
      <div class="operate-options">
        <div class="item">
          <div @click="itemClickForgotPassword">忘记密码？</div>
        </div>
        <div class="item">
          <div @click="itemClickRegister">立即注册</div>
        </div>
      </div>
      <!-- 其他方式登录 -->
      <div class="other-login">
        <el-divider>
          <div class="text">其他方式登录</div>
        </el-divider>
        <div class="type-list">
          <template v-if="types && types == 'scan'">
            <div class="type-item" @click="switchType('account')">
              <div class="img">
                <img src="~@/assets/img/icon/computer.svg" alt="" />
              </div>
              <div class="text">账号登录</div>
            </div>
            <div class="type-item" @click="switchType('sms')">
              <div class="img">
                <img src="~@/assets/img/icon/phone.svg" alt="" />
              </div>
              <div class="text">短信登录</div>
            </div>
          </template>
          <template v-else>
            <div class="type-item" @click="switchType('scan')">
              <div class="img">
                <img src="~@/assets/img/icon/wechat.svg" alt="" />
              </div>
              <div class="text"></div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordInput from "@/components/PasswordInput/index.vue";
import { LoginMixin } from "@/mixins/LoginMixin";
export default {
  name: "Login",
  mixins: [LoginMixin],
  components: {
    PasswordInput,
  },
  props: {
    types: {
      type: String,
      default: 'account'
    },
  },
  data() {
    return {
      componentType: "modal",
      loginBtn:false,
    };
  },
  watch: {
    types(val) {
      if(val){
        this.loginType = val
      }
      this.$refs["formLogin"].clearValidate();

    },
  },
  computed: {},
  created() {

  },
  methods: {
    itemClickBackLogin(type) {
      if(type){
        this.loginType = type
      }
      this.$emit("itemClickBackLogin");
    },
    itemClickForgotPassword() {
      this.$emit("itemClickForgotPassword");
    },
    itemClickRegister() {
      this.$emit("itemClickRegister");
    },
    switchType(type) {
      if(type){
        this.loginType = type
      }
      this.$emit("itemClickBackLogin",type);
    },
  },
};
</script>
<style lang="scss" scoped>
$primary-color: #3399ff;

.tips {
  margin-bottom: 12px;
  color: #606266;
  span {
    color: #f56c6c;
  }
}
.type-list {
  display: flex;
  justify-content: center;
  gap: 24px;
  .type-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      img {
        width: 100%;
      }
    }
    .text {
      color: #333;
    }
  }
}

.operate-options,
.other-login {
  margin-top: 10px;
  text-align: center;
}
.operate-options {
  display: flex;
  justify-content: space-between;
  color: #3399fe;
  & > div {
    cursor: pointer;
  }
}
.other-login {
  color: #dcdfe6;
  .text {
    color: #9e9e9e;
  }
}
.login-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // 微软雅黑字体
  font-family: "Microsoft YaHei";
  color: #000;
  text-align: left;
  font-size: 13px;

  .login-box {
    // width: 390px;
    width: 100%;
    .title {
      position: relative;
      width: 100%;
      height: 50px;
      display: flex;
      font-size: 22px;
      .tabs {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .tab {
          width: 50%;
          height: 50px;
          line-height: 60px;
          text-align: center;
          cursor: pointer;
          border-bottom: 2px solid #e5e5e5;
          &.active {
            color: $primary-color;
            border-bottom: 2px solid $primary-color;
          }
        }
        .tab-line {
          width: 100%;
        }
      }
      .label {
        position: absolute;
        right: 0;
        bottom: 8px;
        width: 50px;
        height: 50px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
    .form {
      width: 100%;
      // height: 280px;
      height: 270px;
      padding-top: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    .links {
      height: 60px;
      margin-bottom: 10px;
      display: flex;
      .item {
        position: relative;
        flex: 1;
        cursor: pointer;
        height: 60px;
        line-height: 60px;
        text-align: center;
        margin: 4px 0;
        border-top: 1px dashed #e8e8e8;
        border-bottom: 1px dashed #e8e8e8;
        &:hover {
          color: $primary-color;
        }
        // 每个 直接间隔 使用伪元素
        &::after {
          content: "";
          width: 1px;
          height: 20px;
          background-color: #e8e8e8;
          margin: 10px 0;
          position: absolute;
          right: 0;
          margin: auto;
          top: 0;
          bottom: 0;
        }
        // 最后一个
        &:last-child::after {
          display: none;
        }
      }
    }
    .help {
      height: 70px;
      .title {
        color: #333;
        margin-top: 14px;
        font-size: 14px;
        height: 20px;
      }
      .content {
        color: #b5b5b5;
        margin-top: 12px;
        .link {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}
.code-image {
  width: 100%;
  height: 44px;
  cursor: pointer;
  border: 1px solid lightgray;
  // border-radius: 5px;
  border-radius: 0;
}

.submit-button {
  width: 100%;
  height: 46px;
  border-radius: 0;
  background: $primary-color;
  color: #fff;
  cursor: pointer;
  &:hover {
    background: darken($primary-color, 10%);
  }
  &:focus {
    background: $primary-color !important;
  }
}

.default-button {
  width: 100%;
  height: 46px;
  border-radius: 0;
  background: #fff;
  border: 1px solid $primary-color;
  color: $primary-color;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}
.default-button:disabled {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
  cursor: not-allowed;
}

/deep/.el-input__inner {
  height: 46px;
  border-radius: 0;
}
/deep/.el-button {
  height: 46px;
  border-radius: 0 !important;
}

/deep/.el-form-item {
  margin-bottom: 10px;
}
/deep/.el-form-item__error {
  position: relative;
  min-height: 12px;
}
</style>
