<template>
  <div class="pls-nav-bubble-wrap pls-nav-account" @mouseover="showBubbleFunc" @mouseleave="hideBubbleFunc">
    <div class="pls-nav-bubble">
      <div class="pls-nav-bubble-inner">
        <a class="pls-nav-account-name">admin</a>
        <ul class="pls-nav-bubble-menu">
          <li class="pls-nav-bubble-menu-item">
            <div class="pls-nav-bubble-menu-id" tabindex="0">
              <a class="pls-nav-bubble-menu-label" href="//console.cloud.tencent.com/developer" hotrep="hp.header.profile.developer">账号信息</a
              ><a href="https://console.cloud.tencent.com/developer/auth" class="pls-nav-bubble-menu-tag default real-cur"
                ><span class="pls-nav-bubble-menu-tag-text"><i class="icon-real-name"></i>已实名认证</span></a
              >
            </div>
          </li>
          <li class="pls-nav-bubble-menu-item">
            <div class="pls-nav-bubble-menu-id" tabindex="0" hotrep="hp.header.profile.developer">
              <a class="pls-nav-bubble-menu-label" href="//console.cloud.tencent.com/expense/overview" hotrep="hp.header.profile.account"
                ><span class="label-inner">费用中心</span></a
              ><a href="//console.cloud.tencent.com/expense/voucher" class="pls-nav-bubble-menu-tag default"
                ><span class="pls-nav-bubble-menu-tag-text">优惠券 0</span></a
              >
            </div>
          </li>
          <li class="pls-nav-bubble-menu-item">
            <a tabindex="0" href="//console.cloud.tencent.com/message/index/unread" hotrep="hp.header.profile.message"
              >未读消息<span class="pls-nav-bubble-menu-tag bubble red"><span class="pls-nav-bubble-menu-tag-text">3</span></span></a
            >
          </li>
          <li class="pls-nav-bubble-menu-item">
            <a tabindex="0" href="//console.cloud.tencent.com/workorder" hotrep="hp.header.profile.workorder">我的工单</a>
          </li>
          <li class="pls-nav-bubble-menu-item">
            <a tabindex="0" href="https://cloud.tencent.com/document/my-space/feedback" hotrep="hp.header.profile.doc-center">我的文档中心</a>
          </li>
        </ul>
        <footer class="pls-nav-bubble-ft">
          <button class="pls-nav-bubble-btn" tabindex="0" hotrep="hp.header.profile.logout" @click="logoutClick">退出</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { setToken } from "@/utils/token";
export default {
  name: "InfoBubble",
  components: {},
  props: {},
  data() {
    return {
      showBubbleFlag: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    logoutClick() {
      // 退出登录
      this.logout();
      // this.$confirm("确认退出？", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //   })
      //   .catch(() => {});
    },
    // 退出登录，清除 token
    async logout() {
      try {
        await this.$api.logout();
      } catch (error) {
        console.log(error);
      }
      setToken("");
      // this.$router.push("/home");
      location.reload();
    },
    showBubbleFunc() {
      this.$emit("show");
    },
    hideBubbleFunc() {
      this.$emit("hide");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.pls-nav-bubble-wrap {
  cursor: pointer;
  position: relative;
}

.pls-nav-bubble-wrap .pls-nav-bubble {
}

.pls-nav-bubble .pls-nav-account .pls-nav-bubble {
}

.pls-nav-bubble {
  position: relative;
  left: 0;
  top: 40px;
  min-width: 140px;
  margin-top: 12px;
  width: 220px;
  left: auto;
  right: 160px;
  text-align: left;
  z-index: 2;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #e4e6eb;
  box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, 0.1);
}

.pls-nav-bubble-wrap:hover {
}

.pls-nav-bubble {
}

.pls-nav-bubble-wrap:hover .pls-nav-bubble {
  visibility: visible;
}

.pls-nav-account .pls-nav-bubble-inner {
  padding-top: 20px;
}

.pls-nav-bubble-inner {
  position: relative;
  background: #fff;
}
.pls-nav-account-name {
  display: block;
  margin-bottom: 20px;
  padding: 0 20px;
  font-weight: 500;
  font-size: 16px;
  color: #16181a;
  line-height: 24px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pls-nav-account .pls-nav-bubble-menu {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 20px;
}
.pls-nav-bubble-menu {
  padding: 12px 0;
}
.pls-nav-bubble-menu-item {
  margin-bottom: 8px;
}
.pls-nav-bubble-menu-item > a {
  position: relative;
  display: block;
  padding: 0 20px;
  font-size: 14px;
  color: #495770;
  line-height: 22px;
  white-space: nowrap;
}
.pls-nav-bubble-menu .pls-nav-bubble-menu-id {
  position: relative;
  display: block;
  padding: 0 20px;
  font-size: 14px;
  color: #495770;
  line-height: 22px;
  white-space: nowrap;
}
.pls-nav-bubble-menu .pls-nav-bubble-menu-label {
  display: block;
  color: #495770;
}
.pls-nav-bubble-menu-tag.real-cur {
  border: 1px solid #0052d9;
  color: #0052d9;
}
.pls-nav-bubble-menu-tag.default {
  top: 3px;
  height: 16px;
  border: 1px solid #0052d9;
  border-radius: 2px;
  font-size: 12px;
  color: #0052d9;
  text-align: center;
  line-height: 14px;
}
.pls-nav-bubble-menu-tag {
  position: absolute;
  right: 20px;
  top: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pls-nav-bubble-menu-tag-text {
  display: block;
  -webkit-transform: scale(0.8333333333);
  transform: scale(0.8333333333);
}
.pls-nav-bubble-menu-tag.real-cur {
  border: 1px solid #0052d9;
  color: #0052d9;
}
.pls-nav-bubble-ft {
  margin: 0 -2px -2px;
  display: flex;
}
.pls-nav-bubble-btn {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  flex: 1;
  background: #fff;
  border: 2px solid #fff;
  box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, 0.1);
  padding: 0 22px;
  font-size: 14px;
  color: #495770;
  line-height: 42px;
  text-align: left;
}
</style>
