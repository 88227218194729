import { phoneReg, passwordReg } from "@/utils/validate";
import { encryption } from "@/utils/encryption.js";
import { setLocal, getLocal } from "@/utils/utils";
import { setToken } from "@/utils/token";
export const LoginMixin = {
  data() {
    return {
      // 组件类型，页面还是弹窗
      componentType: "page",
      // 登录方式
      // 账号登录 短信登录
      types: "account",
      resultlogin:{},
      // 登录按钮，加载中
      loginBtn: false,
      // 加密字符串
      encryptedString: {
        key: "",
        iv: "",
      },
      // 倒计时
      state: {
        time: 60,
        interval: null,
        smsSendBtn: false,
      },
      // 表单数据
      form: {
        username: "",
        password: "",
        mobile: "",
        captcha: "",
        inputCode: "",
      },
      // 表单验证规则
      validatorRules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: this.validateMobile, trigger: "blur" },
        ],
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        inputCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      // 图片验证码
      currdatetime: "",
      randCodeImage: "",
      requestCodeSuccess: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    types(val) {
      if (val == "account" || val == "mobile") {
        this.$refs["formLogin"].clearValidate();
      }
      this.resetCountdown();
    },
  },
  created() {
    this.currdatetime = new Date().getTime();
    this.handleChangeCheckCode();
    // this.getEncryptedString();
  },
  methods: {
    // 切换登录方式
    switchType(type) {
      if (this.types == "account" || this.types == "mobile") {
        this.$refs["formLogin"].clearValidate();
      }
      this.types = type;
    },
    // 立即注册
    handleRegister() {
      console.log("立即注册");
      this.$router.push({ name: "register" }).catch((err) => {});
    },
    // 忘记账号
    handleForgetAccount() {
      console.log("忘记账号");
    },
    // 忘记密码
    handleForgetPassword() {
      console.log("忘记密码");
      this.$router.push({ name: "retrieve" }).catch((err) => {});
    },
    // 刷新验证码
    handleChangeCheckCode() {
      this.currdatetime = new Date().getTime();
      this.$api.getAction(`/syscheck/getCheckCode2`, { key: this.currdatetime }).then((res) => {
        if (res.success) {
          this.randCodeImage = res.result;
          this.requestCodeSuccess = true;
        } else {
          this.$message.error(res.message);
          this.requestCodeSuccess = false;
        }
      });
    },
    // 登录
    handleSubmit() {
      // 使用账户密码登陆
      if (this.types === "account") {
        this.$refs["formLogin"].validate((valid) => {
          if (valid) {
            // let username = encryption(this.form.username, this.encryptedString.key, this.encryptedString.iv);
            // let password = encryption(this.form.password, this.encryptedString.key, this.encryptedString.iv);
            let { username, password } = this.form;
            let loginParams = {
              grant_type: "password",
              username,
              password,
              captcha: this.form.inputCode,
              remember_me: false,
              checkKey: this.currdatetime,
              userType:"01"
            };
            console.log("loginParams");
            console.log(loginParams);
            this.onLogin(loginParams);
          }
        });
        // 使用手机号登陆
      } else if (this.types === "sms") {
        this.$refs["formLogin"].validate((valid) => {
          if (valid) {
            // 手机号验证码登录成功后的逻辑
            this.phoneLogin();
          } else {
            console.log(valid);
          }
        });
      } else if (this.types === "bindPhone") {
        this.$refs["formLogin"].validate((valid) => {
          if (valid) {
            if (this.form.captcha == undefined) {
              this.cmsFailed("请输入短信验证码");
            } else {
              var params = {};
              params.phone = this.form.mobile;
              params.smscode = this.form.captcha;
              params.sftype = "02";
              this.loginBtn = true;
              if(this.resultlogin){
                params.token=this.resultlogin.token;
              }
              // this.$store.dispatch("SetCode", "CU_BINDLDENTIFIER");
              this.$api.getAction("/identity/user/Bindldentifier", params).then((res) => {
                this.loginBtn = false;
                if (res.success && res.message &&  !res.message.includes("错误")) {
                  this.$message.success(res.message);
                  if(this.resultlogin){
                    const result = this.resultlogin;
                    const userInfo = result.userInfo;

                      console.log("成功登陆");
                      console.log("userInfo");
                      console.log(userInfo);
                      this.$store.dispatch("setUserInfo", userInfo);
                    this.$store.dispatch("getUserInfoByUserId");
                      this.$store.dispatch("getVipList");
                      // setLocal("userInfo", JSON.stringify(userInfo));
                      // setLocal("token", result.token);
                      setToken(result.token);
                      // 增加一个判断，用户有没有绑定手机号
                      // 如果没有绑定手机号，跳转到绑定手机号页面
                      // this.$message.success("登录成功");
                      // 提示信息
                      // this.$emit("update:visible", false);
                      // this.$emit("loginSuccess");
                      if (this.componentType == "page") {
                        this.$router.push({name: "home"}).catch((err) => {
                        });
                      } else if (this.componentType == "modal") {
                        this.$emit("ok");
                      }
                    }else {
                    this.$store.dispatch("getUserInfonew");
                    if (this.componentType == "page") {
                      this.$router.push({name: "home"}).catch((err) => {
                      });
                    } else if (this.componentType == "modal") {
                      this.$emit("ok");
                    }
                  }
                  // this.handleBindPhone(formData)
                } else {
                  this.$message.warning(res.message);
                }
              }).catch((err)=>{
                this.loginBtn = false
                this.$message.warning(err.message || '系统异常');
              })
            }
          }
        });
      }
    },
    // 用户名密码登录
    async onLogin(params) {
      this.loginBtn = true;
      try {
        let response = await this.$api.login(params);
        this.loginBtn = false;
        if (response.success && response.result) {
          const result = response.result;
          const userInfo = result.userInfo;
          if (userInfo.phone) {
          console.log("成功登陆");
          console.log("userInfo");
          console.log(userInfo);
          this.$store.dispatch("setUserInfo", userInfo);
          this.$store.dispatch("getVipList");
          // setLocal("userInfo", JSON.stringify(userInfo));
          // setLocal("token", result.token);
          setToken(result.token);
          // 增加一个判断，用户有没有绑定手机号
          // 如果没有绑定手机号，跳转到绑定手机号页面
            this.$message.success(response.message);
            // 提示信息
            this.$emit("update:visible", false);
            this.$emit("loginSuccess");
            if (this.componentType == "page") {
              this.$router.push({ name: "home" }).catch((err) => {});
            } else if (this.componentType == "modal") {
              this.$emit("ok");
            }
          } else {
            this.types = "bindPhone";
            this.resultlogin = result;
            this.$emit("okNew");
            // 绑定手机号
          }

        } else {
          // 提示信息
          this.$message.error(response.message);
          this.handleChangeCheckCode();
        }
      } catch (error) {
        this.loginBtn = false;
      }
    },
    // 手机号登录
    phoneLogin() {
      let params = {
        username: this.form.mobile,
        captcha: this.form.captcha,
        grant_type: "sms",
        userType:"01"
      };
      this.loginBtn = true;
      this.$api.postAction("/identity/sys/login", params).then((res) => {
        this.loginBtn = false;
        if (res.success && res.result) {
          const result = res.result;
          const userInfo = result.userInfo;
          this.$store.dispatch("setUserInfo", userInfo);
          this.$store.dispatch("getVipList");
          console.log("成功登陆");
          console.log("userInfo");
          console.log(userInfo);

          // setLocal("userInfo", JSON.stringify(userInfo));
          // setLocal("token", result.token);
          setToken(result.token);
          this.$message.success(res.message);
          this.$emit("update:visible", false);
          if (this.componentType == "page") {
            this.$router.push({ name: "home" }).catch((err) => {});
          } else if (this.componentType == "modal") {
            this.$emit("ok");
          }
          this.$emit("loginSuccess");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取验证码
    getCaptcha(e) {
      e.preventDefault();
      // 对单个表单数据进行校验 validateField
      this.$refs["formLogin"].validateField("mobile", (err) => {
        console.log("validvalidvalid");
        console.log(err);
        if (err) {
          console.log("请输入手机号");
        } else if (!err) {
          let params = {
            mobile: this.form.mobile,
            // 手机登录
            smsmode: "0",
            userType:"01"
          };
          if (this.types === "bindPhone") {
            // 绑定手机号
            params.smsmode = "3";
          }
          this.$api.postAction("/identity/sys/sms", params).then((res) => {
            if (res.success) {
              // 提示信息
              // this.$notify({
              //   title: "温馨提示",
              //   message: "验证码发送中",
              //   type: "success",
              // });
              this.resetCountdown()
              this.startCountdown();
            } else {
              console.log(res.message);
              // 提示信息
              this.$message.error(res.message);
              // this.$notify({
              //   title: "温馨提示",
              //   message: res.message,
              //   type: "error",
              // });
              clearInterval(this.state.interval);
              this.state.time = 0;
              this.state.smsSendBtn = false;
            }
          });
        }
      });
    },
    cmsFailed(err) {
      this.$message.error(err);
      // this.$notification["error"]({
      //   message: "验证错误",
      //   description: err,
      //   duration: 4,
      // });
    },
    // 开始倒计时
    startCountdown() {
      this.state.smsSendBtn = true;
      this.state.interval = setInterval(() => {
        if (this.state.time-- <= 0) {
          this.state.time = 60;
          this.state.smsSendBtn = false;
          clearInterval(this.state.interval);
        }
      }, 1000);
    },
    // 重置倒计时
    resetCountdown() {
      clearInterval(this.state.interval);
      this.state.time = 60;
      this.state.smsSendBtn = false;
    },
    // 获取加密字符串
    async getEncryptedString() {
      let encryptedString = getLocal("encryptedString");
      if (encryptedString) {
        this.encryptedString = JSON.parse(encryptedString);
        return;
      }
      let res = await this.$api.getAction("/sys/getEncryptedString", {});
      this.encryptedString = res.result;
      setLocal("encryptedString", JSON.stringify(this.encryptedString));
    },
    // 校验手机号码
    validateMobile(rule, value, callback) {
      if (!value || new RegExp(/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/).test(value)) {
        callback();
      } else {
        callback("您的手机号码格式不正确");
      }
    },
    // 验证码改变
    inputCodeChange(e) {},
  },
  mounted() {
    // 创建定时器，每2秒调用一次updateMessage方法
    this.intervalId = setInterval(this.handleChangeCheckCode, 60000);
  },
  beforeDestroy() {
    // 清除定时器
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
};
