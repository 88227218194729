export const RetrieveMixin = {
  data() {
    return {
      currentTab: 0,
      stepList: [
        {
          name: "找回密码",
        },
        {
          name: "重置密码",
        },
        {
          name: "重置密码",
        },
      ],
      // 用户信息
      userList: {
        username: "",
        phone: "",
      },

      loading: false,
      captcha: "",
      show: true,
      state: {
        time: 60,
        interval: null,
        smsSendBtn: false,
      },
      form: {
        mobile: "",
        code: "",
        password: "",
        confirmPassword: "",
      },
      validatorRules: {
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: this.validateMobile, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/,
            message: "密码由至少8位数字、大小写字母和特殊符号组成",
            trigger: "change",
          },
          {
            validator: this.handlePassword,
            trigger: "change",
          },
        ],
        confirmPassword: [
          { required: true, message: "请输入确认密码", trigger: "change" },
          { validator: this.handlePasswordCheck, trigger: "change" },
        ],
      },
    };
  },
  computed: {
    stepName() {
      return this.stepList[this.currentTab].name;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {},
  beforeDestroy() {},
  methods: {
    getCaptcha(e) {
        e.preventDefault();
        let username = this.form.username;
        let mobile = this.form.mobile;
        if (!mobile || !new RegExp(/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/).test(mobile)) {
          return;
        }

        let that = this
      this.state.smsSendBtn = true
      let interval = window.setInterval(() => {
        if (that.state.time-- <= 0) {
          that.state.time = 60
          that.state.smsSendBtn = false
          window.clearInterval(interval)
        }
      }, 1000)
        // const hide = this.$message.loading("验证码发送中..", 0);
        // 手机短信
        let type = "1";
        let formData = {
          type,
          phone: this.form.mobile,
          userType:"01"
        };
        console.log(formData);
        // 发送验证码
        this.$api.getAction("/identity/user/retrievePassword", formData).then((res) => {
          if (res.success) {
            // 提示信息
            // this.$notify({
            //   title: "温馨提示",
            //   message: "验证码发送中",
            //   type: "success",
            // });
            this.resetCountdown()
            this.startCountdown();
          } else {

            console.log(res.message);
            clearInterval(this.state.interval);
            this.state.time = 0;
            this.state.smsSendBtn = false;
            this.$message.error(res.message);
            // 提示信息
            // this.$notify({
            //   title: "温馨提示",
            //   message: res.message,
            //   type: "error",
            // });
          }
          // setTimeout(hide, 500);
        });

    },
    // 开始倒计时
    startCountdown() {
      this.state.smsSendBtn = true;
      this.state.interval = setInterval(() => {
        if (this.state.time-- <= 0) {
          this.state.time = 60;
          this.state.smsSendBtn = false;
          clearInterval(this.state.interval);
        }
      }, 1000);
    },
    // 重置倒计时
    resetCountdown() {
      clearInterval(this.state.interval);
      this.state.time = 60;
      this.state.smsSendBtn = false;
    },
    cmsFailed(err) {
      this.$message.error(err);
      // this.$notification["error"]({
      //   message: "验证错误",
      //   description: err,
      //   duration: 4,
      // });
    },
    // 下一步
    nextStep() {
      let username = this.form.username;
      let mobile = this.form.mobile;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.form.code) {
            this.cmsFailed("请输入短信验证码");
          } else {
            var params = {};
            params.code = this.form.code;
            params.username = this.form.username;
            params.mobile = this.form.mobile;
            this.userList.username = this.form.username;
            this.userList.phone = this.form.mobile;
            console.log("params");
            console.log(params);
            this.loading = true;
            this.$api.getAction("/identity/user/validationPassword", params).then((res) => {
              this.loading = false;
              if (res.success) {
                this.currentTab++;
              } else {
                this.cmsFailed(res.message);
              }
            });
          }
        }
      });
    },
    // 确定并重置
    handleSubmit() {
      let that = this;
      that.loading = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          var params = {};
          // 用户信息
          console.log(this.userList);
          params.phone = this.userList.phone;
          params.password = this.form.password;
          this.$api.getAction("/identity/user/passwordzhChange", params).then((res) => {
            this.loading = false;
            if (res.success) {
              this.currentTab++;
            } else {
              this.passwordFailed(res.message);
            }
          });
        } else {
          that.loading = false;
        }
      });
    },
    validateMobile(rule, value, callback) {
      if (!value || new RegExp(/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/).test(value)) {
        callback();
      } else {
        callback("您的手机号码格式不正确");
      }
    },
    handlePassword(rule, value, callback) {
      let confirmPassword = this.form.confirmPassword;
      if (value && confirmPassword && value.trim() !== confirmPassword.trim()) {
        callback(new Error("两次密码不一致"));
      }
      callback();
    },
    handlePasswordCheck(rule, value, callback) {
      let password = this.form.password;
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error("两次密码不一致"));
      }
      callback();
    },
    passwordFailed(err) {
      this.$message.error(err);
      // this.$notification["error"]({
      //   message: "更改密码失败",
      //   description: err,
      //   duration: 4,
      // });
    },
    // 返回登录
    backLogin() {
      this.$router.push({ name: "login" }).catch((err) => {});
    },
  },
};
