<template>
  <!-- 初始状态下隐藏提示框 -->
  <transition name="fade">
    <div class="custom-confirm" :class="config.customClass" v-if="isShow">
      <!-- 背景遮罩 -->
      <div class="mask" @click="closeMask"></div>
      <div class="confirm-box">
        <div class="title-box" v-if="config.title">
          <div class="logo">
            <img src="~@/assets/img/newstyle/popup/warning.png" alt="" />
          </div>
          <div class="text">
            {{ title || config.content || config.title }}
          </div>
        </div>
        <div class="content-box" v-html="message || config.message"></div>
        <div class="btn-box">
          <div class="btn cancel" @click="handleCancel">{{ config.cancelButtonText }}</div>
          <div class="btn confirm" @click="handleConfirm">{{ config.confirmButtonText }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ConfirmComponent",
  data() {
    return {
      isShow: false,
      config: {
        title: "提示",
        message: "",
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        customClass: "",
        onOk: () => {},
        onCancel: () => {},
      },
      title: "",
      message: "",
    };
  },
  methods: {
    // 关闭弹窗
    close() {
      this.isShow = false;
    },
    closeMask() {
      // this.close();
    },
    handleCancel() {
      this.config.onCancel();
      this.close();
    },
    handleConfirm() {
      this.config.onOk();
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.custom-confirm {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  z-index: 99999;
}
.confirm-box {
  position: relative;
  top: 100px;
  left: 0;
  right: 0;
  margin: auto;
  // transform: translate(-50%, -50%);
  width: 300px;
  // 当文字内容过多时，弹窗宽度自适应
  max-width: 416px;
  width: max-content;
  padding: 20px 10px;
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  // border: 2px solid #eee;
  text-align: center;
  z-index: 3001;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-box {
  text-align: center;
  // font-size: 24px;
  font-size: 18px;
  padding: 0 18px;
  color: #000;
  display: flex;
  justify-content: space-between;
  width: 100%; /* occupy full width */
  .logo {
    width: 32px;
    margin-right: 16px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .text {
    width: 100%;
    padding-right: 32px;
    text-align: center;
    margin-top: 4px;
    display: block;
    overflow: hidden;
    color: #000000d9;
    font-weight: 500;
    line-height: 1.4;
  }
}

.content-box {
  width: 100%;
  color: #000000a6;
  font-size: 14px;
  text-align: center;
  // margin-top: 8px;
  color: #000000a6;
  font-size: 14px;
  padding: 20px 0;
}
.btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  // line-height: 40px;
  // height: 40px;
  line-height: 30px;
  height: 30px;
  // min-width: 80px;
  min-width: 60px;
  padding: 0 16px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  &.cancel {
    // background-color: #ededed;
    // color: #333;
    color: #c3c3c3;
    cursor: pointer;
    &:hover {
      color: #5cb3ff;
    }
  }
  &.confirm {
    background-color: #3399ff;
    color: #fff;
    cursor: pointer;
    margin-left: 12px;
    &:hover {
      opacity: 0.8;
    }
  }
}

.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.4);
  background: #00000073;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 3000;
}
</style>
