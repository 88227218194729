<template>
  <div class="progress-container">
    <div class="progress-bar" :style="{ width: progress + '%' }"></div>
    <div class="progress-label">
      <!-- 
        {{ progress }}%
       -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-container {
  width: 100%;
  height: 14px;
  background-color: #eaf4fe;
  border-radius: 5px;
  position: relative;
}

.progress-bar {
  height: 100%;
  border-radius: 8px;
  background-color: #3399fe;
  transition: width 0.3s ease;
}

.progress-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
}
</style>
