<template>
  <div class="scan-code">
    <wxlogin href="" id="wxcode" theme="" :appid="appid" scope="snsapi_login" :redirect_uri="encodeURIComponent(redirect_uri)"></wxlogin>
    <img style="width: 160px; height: 160px" :src="QRImgUrl" />
  </div>
</template>

<script>
import wxlogin from "vue-wxlogin";
import QRCode from "qrcode";
/* 
  <wxlogin
    :appid="app_id" // 应用唯一标识（认证网页应用获得） 
    :scope="'snsapi_login'" //  应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
    :theme="'black'" // 主题
    :redirect_uri="url" // 回调地址
    :state="" // 传给后台的数据
  > 
*/
export default {
  name: "ScanCode",
  components: {
    wxlogin,
  },
  props: {},
  data() {
    return {
      href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7CiAgICB3aWR0aDogMTUwcHg7Cn0KLmltcG93ZXJCb3ggLnRpdGxlIHsKICAgIHRleHQtYWxpZ246IGxlZnQ7CiAgICBmb250LXNpemU6IDE2cHg7CiAgICBmb250LXdlaWdodDogYm9sZDsKfQ==",
      // 应用唯一标识（认证网页应用获得）
      appid: "",
      // smp 测试用的论无忧的appid
      appid: "wx42e046256dad3d7b",
      // 当前路径
      redirect_uri: "",
      // smp 测试用的论无忧的redirect_uri
      redirect_uri: "http://www.lun51.com/test/registerCallBack",

      QRlink: "https://www.bilibili.com/",
      // 二维码地址，生成二维码图片
      QRImgUrl: "",
      QRSize: 200, //二维码大小
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 生成二维码
    getQRcode() {
      return new Promise((resolve, reject) => {
        resolve();
        let opts = {
          errorCorrectionLevel: "L", //容错级别
          type: "image/png", //生成的二维码类型
          quality: 1, //二维码质量
          margin: 0, //二维码留白边距
          width: this.QRSize, //宽
          height: this.QRSize, //高
          text: "", //二维码内容
          color: {
            dark: "#000", //前景色
            light: "#fff", //背景色
          },
        };
        //this.QRlink 生成的二维码地址url
        QRCode.toDataURL(this.QRlink, opts, (err, url) => {
          if (err) {
            console.log(err);
            reject(err);
          }
          //将生成的二维码路径复制给data的QRImgUrl
          this.QRImgUrl = url;
        });
      });
    },
  },
  created() {
    // 生成二维码
    this.getQRcode();
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}
</style>
