import { getToken } from "@/utils/token";
// axios 自定义配置
export default {
  baseURL: window._CONFIG["BASE_API"] || window._CONFIG["domainURL"],
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "X-Access-Token": getToken(),
  },
  data: {},
  timeout: 10000,
  withCredentials: true,
  responseType: "json",
};
