<template>
  <div class="wrapper">
    <scan-code />
    <div class="count-to">
      <CountTo :startVal="startVal" :endVal="endVal" :duration="duration" />
    </div>
    <custom-upload :headers="headers" :action="uploadAction" />
  </div>
</template>

<script>
import CustomUpload from "@/components/CustomUpload.vue";
import { getToken } from "@/utils/token";
import ScanCode from "@/components/wechat/ScanCode.vue";
import CountTo from "vue-count-to";
export default {
  name: "compose",
  components: {
    CustomUpload,
    ScanCode,
    CountTo,
  },
  props: {},
  data() {
    return {
      url: {
        fileUpload: window._CONFIG["domainURL"] + "/sys/common/upload",
      },
      headers: {},
      startVal: 0,
      endVal: new Date().getFullYear(),
      duration: 1000,
    };
  },
  watch: {},
  computed: {
    uploadAction: function () {
      return this.url.fileUpload;
    },
  },
  methods: {},
  created() {
    const token = getToken();
    this.headers = {
      "X-Access-Token": token,
    };
  },
  mounted() {},
};
</script>
<style scoped>
.vue-count-to {
  width: 100%;
  height: 100%;
}
.count-to {
  margin: 100px auto;
}
.count-to span {
  font-size: 30px;
  font-weight: 700;
  font-family: "fantasy Microsoft YaHei";
}
.wrapper {
}
</style>
