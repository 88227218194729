import Vue from "vue";
import App from "./App.vue";
// 自定义指令
import "./utils/directive";

// 引入路由对象
import router from "./router";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./element-variables.scss";
ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.use(ElementUI);

// 自定义全局popup弹框
import Msg from '@/components/CustomPopup/message.js'
Vue.use(Msg);
// 自定义全局confirm弹框
import ConfirmPlugin from '@/components/CustomPopup/confirm.js'
Vue.use(ConfirmPlugin)

import api from "./http/index";
Vue.use(api);
// 引入swiper.css
import "swiper/css/swiper.min.css";

import "@/assets/scss/index.scss"; // global css



Vue.prototype.$EventBus = new Vue();
Vue.config.productionTip = false;
// eslint-disable-next-line
import store from "./store";
new Vue({
  store,
  // 挂载路由对象
  router,
  render: (h) => h(App),
}).$mount("#app");
