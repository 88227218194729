import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import getters from './getters'
Vue.use(Vuex)
const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})
const store = new Vuex.Store({
  modules,
  getters,
  plugins: [createPersistedState({
    // storage: window.sessionStorage,
    storage: window.localStorage,
    reducer(val) {
      return {
        // 只储存state中的user
        settings: val.settings,
        user: val.user,
      }
    }
  })]
})
export default store