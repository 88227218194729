<template>
  <div class="custom-container">
    <div class="custom-sider">
      <div class="menu-list">
        <ul>
          <li v-for="item in list" :key="item.key">
            <div class="item-class menu-parent-item" v-if="item.children && item.children.length">
              <img class="img" :src="require(`@/assets/img/me/${item.img}`)" alt="" />
              <div class="text">
                {{ item.title }}
              </div>
            </div>
            <ul v-if="item.children && item.children.length">
              <li v-for="subItem in item.children" :key="subItem.key" @click="handleClick(subItem)">
                <div class="item-class" :class="{ 'current-item': subItem.key == selectedKey }">
                  <!-- <img class="img" :src="require(`@/assets/img/me/${subItem.img}`)" alt="" /> -->
                  <div class="text sub-text">
                    {{ subItem.title }}
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="content">
      <!-- Your content goes here -->
      <component :is="selectedComponent" :selectedKey="selectedKey" :keyNew="selectedKey"/>
    </div>
  </div>
</template>

<script>
// import { filterObj } from '@/utils/util'
// import { httpAction, getAction } from '@api/manage'
// import JEllipsis from '@/components/jeecg/JEllipsis'
// import AccountSecurity from './AccountSecurity.vue'
// import PersonalInformation from './PersonalInformation.vue'
export default {
  name: "AccountSettingsList",
  components: {
    // AccountSecurity,
    // PersonalInformation,
  },
  mixins: [],
  props: {},
  data() {
    return {
      // 左侧分类菜单
      list: [
        // 角色管理
        {
          path: "/me",
          id: "settings",
          key: "settings",
          icon: "setting",
          title: "我的账号",
          img: "account-information.svg",
          parentId: "",
          children: [
            // 个人信息
            {
              path: "/PersonalInformation",
              id: "personal",
              key: "personal",
              title: "个人信息",
              img: "personal-information.svg",
              component: () => import(`@/views/me/PersonalInformation`),
              parentId: "settings",
            },
            // 账户安全
            {
              path: "/AccountSecurity",
              id: "security",
              key: "security",
              title: "账号设置",
              img: "account-setting.svg",
              component: () => import(`@/views/me/AccountSecurity`),
              parentId: "settings",
            },
            // 学校账号
            // {
            //   path: "/SchoolAccount",
            //   id: "school",
            //   key: "school",
            //   title: "学校账号",
            //   img: "school-account.svg",
            //   component: () => import(`@/views/me/SchoolAccount`),
            //   parentId: "settings",
            // },
          ],
        },
      ],
      current: "",
      openKeys: [],
      defaultSelectedKeys: [],
      defaultOpenKeys: [],
      selectedKey: "personal",
    };
  },
  watch: {
    openKeys(val) {
      console.log("openKeys");
      console.log(val);
    },
  },
  computed: {
    // 选中的组件
    selectedComponent() {
      let component = null;
      if (this.selectedKey) {
        // 对 list 进行扁平化处理
        let list = [];
        this.list.forEach((item) => {
          if (item.children && item.children.length) {
            list = list.concat(item.children);
          } else {
            list.push(item);
          }
        });
        let item = list.find((item) => item.key === this.selectedKey);
        if (item) {
          component = item.component;
        }
      }
      return component;
    },
  },
  methods: {
    handleBack() {
      // 判断是否有上一页
      if (this.$route.path === this.$route.matched[0].path) {
        this.$router.push({ path: "/" });
      } else {
        this.$router.go(-1);
      }
    },
    handleClick(e) {
      this.selectedKey = e.key;

      // this.$router.push({ path: "/me?key="+e.key });
    },
  },
  created() {
    console.log( this.$route.query.key)
    this.selectedKey =  this.$route.query.key?this.$route.query.key:"personal"
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.custom-container {
  min-height: calc(100vh - 120px);
  display: flex;
  text-align: center;
}

.custom-sider {
  flex: 0 0 200px;
  border-right: 1px solid #f5f5f5;
  padding: 10px;
  background-color: #fff;
}

.top-button {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
}

.menu-list {
  overflow-y: auto;
  max-height: calc(100% - 40px);
}

.content {
  flex: 1;
  padding: 10px;
  background-color: #fff;
}

.item-class {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  background: linear-gradient(180deg, #f3f5f8, #fff);
  box-shadow: 4px 6px 8px rgba(55, 99, 170, 0.1);
  border-radius: 4px;
  margin: 12px;
  justify-content: space-around;
  color: #3c3c3c;
  .img {
    width: 40px;
    transition: transform 0.6s ease-in-out;
  }

  .text {
    font-size: 16px;
    width: 100%;
  }
  .sub-text {
    font-size: 14px;
  }
  &:hover {
    background: #fff;
    box-shadow: 4px 6px 8px rgba(55, 99, 170, 0.1), -8px -8px 20px rgba(55, 99, 170, 0.05);
    color: #3399fe;
    .img {
      transform: scale(1.1);
    }
    .text {
      // transform: translateY(-2px);
    }
  }
}

.current-item {
  color: #3399fe;
  border: 2px solid #3399fe;
}

.menu-parent-item {
  // background: #fff;
  background: transparent;
  // box-shadow: 4px 6px 8px rgba(55, 99, 170, 0.1), -8px -8px 20px rgba(55, 99, 170, 0.05);
  box-shadow: none;
  cursor: default;
  &:hover {
    // background: #fff;
    background: transparent;
    // box-shadow: 4px 6px 8px rgba(55, 99, 170, 0.1), -8px -8px 20px rgba(55, 99, 170, 0.05);
    box-shadow: none;
    // color: #3399fe;
    color: #3c3c3c;
    .img {
      transform: scale(1);
      // transform: translateY(0px);
    }
    .text {
      // transform: translateY(-2px);
    }
  }
}
</style>
