
import Vue from 'vue'
// 注册一个全局自定义指令 `v-focus`
Vue.directive('focus', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el) {
    // 聚焦元素
    el.focus()
  }
})

Vue.directive('click', {
  inserted(el, binding) {
      el.addEventListener('click', () => {
          if (!el.disabled) {
              el.disabled = true;
              setTimeout(() => {
                  el.disabled = false;
              }, binding.value || 1000)
          }
      })
  }
})

Vue.directive("color",{
  // bind：只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置
  bind:function(el,binding){
      console.log(binding.name)
      //有值就用，没值默认颜色
      el.style.color=binding.value || "#F56C6C"
  },
  // inserted：被绑定元素插入父节点时调用
  inserted:function(el){
    console.log(el)
  },
  // update：所在组件的 VNode 更新时调用
  update:function(el){
    console.log(el)
  }
})

Vue.directive('ellipsis', {
    bind(el, binding) {
        const MOUSEENTER_EVENT = 'mouseenter';
        const MOUSELEAVE_EVENT = 'mouseleave';
        // const ORIGINAL_TITLE = 'data-original-title';
        // 创建元素用于显示全部内容
        const tooltip = document.createElement('div');
        tooltip.setAttribute('class', 'tooltip');
        tooltip.style.position = 'absolute';
        tooltip.style.zIndex = '1000';
        tooltip.style.display = 'none';
        tooltip.style.background = '#f9f9f9';
        tooltip.style.color = '#000';
        tooltip.style.border = '1px solid #ebebeb';
        tooltip.style.borderRadius = '4px';
        tooltip.style.padding = '5px';
        tooltip.style.fontSize = '12px';

        // 获取绑定的内容
        const content = binding.value.content;
        const maxWidth = binding.value.maxWidth || 150;

        el.style.overflow = 'hidden';
        el.style.textOverflow = 'ellipsis';
        el.style.whiteSpace = 'nowrap';
        el.style.maxWidth = `${maxWidth}px`;

        // 鼠标移入事件
        // el.addEventListener(MOUSEENTER_EVENT, () => {
        //     tooltip.innerText = content;
        //     tooltip.style.display = 'block';
        //     tooltip.style.top = `${el.offsetTop + el.offsetHeight + 10}px`;
        //     tooltip.style.left = `${el.offsetLeft}px`;
        //     document.body.appendChild(tooltip);
        // });
        //
        // // 鼠标移出事件
        // el.addEventListener(MOUSELEAVE_EVENT, () => {
        //     tooltip.style.display = 'none';
        // });
    }
});
