const state = {
  themeImg: 'blue',
  botColor: 'white',
  websiteName: "Web前端",//系统名称
  copyright: "",
}
const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  }
}
const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}
export default {
  // 为了解决不同模块命名冲突的问题，将不同模块的namespaced:true
  namespaced: true,
  state,
  mutations,
  actions
}