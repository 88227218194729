<template>
  <div class="pop-container" v-if="isShow">
    <div class="message-container" :style="{ marginTop: top + 'px' }">
      <div class="icon-container">
        <div class="icon-container-success" v-if="type === 'success'">
          <img src="~@/assets/img/newstyle/popup/success.png" alt="" />
        </div>
        <div class="icon-container-error" v-if="type === 'error'">
          <img src="~@/assets/img/newstyle/popup/fail.png" alt="" />
        </div>
        <div class="icon-container-error" v-if="type === 'warning'">
          <img src="~@/assets/img/newstyle/popup/warning.png" alt="" />
        </div>
      </div>
      <span class="message-text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageComponent',
  props: {
    type: {
      // type控制是成功还是失败
      type: String,
      default: 'success',
    },
    text: {
      // 弹窗的文字信息
      type: String,
      default: '',
    },
    isShow: {
      // 整个遮罩和弹窗是否显示
      type: Boolean,
      default: true,
    },
    top: {
      // 弹窗距离顶部的距离
      type: String | Number,
      default: '40',
    },
  },
}
</script>
<style scoped lang="scss">
.pop-container {
  display: flex;
  justify-content: center;
  // align-items: center;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  padding: 5px 30px;
  min-height: 70px;
  background: #fff;
  text-align: center;
  // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 2px solid #eee;
  margin: 0 12px;
  // 出现的时候，从上往下弹出
  // animation: slideIn 0.5s ease-in-out;
  // 出现的时候，抖动一下
  // animation: shake 1s ease-in-out;
}

@keyframes slideIn {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shake {
  // 左右连续抖动
  0% {
    transform: translateX(-20px);
  }
  20% {
    transform: translateX(20px);
  }
  40% {
    transform: translateX(-20px);
  }
  60% {
    transform: translateX(20px);
  }
  80% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

.icon-container {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 12px;
  img{
    width: 100%;
  }
  &-error {
    // background-color: #fe1b1b;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    .icon-close {
      position: absolute;
      right: 5px;
      bottom: 5px;
      font-weight: 900;
    }
  }
  &-success {
    // background-color: #4ad991;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    .icon-checkmark {
      position: absolute;
      right: 4px;
      bottom: 5px;
      font-weight: 900;
    }
  }
}
.message-text {
  margin-left: 0px;
  font-weight: 500;
  // font-size: 24px;
  font-size: 18px;
  line-height: 24px;

  //display: block;
  //  /* white-space: normal; */
  //  width: calc(100% - 44px);
  //  word-break: break-all;
  //  padding: 6px 0;
}
</style>
