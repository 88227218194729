// token.js
import { getItem, setItem, removeItem } from "@/utils/store";
const TOKEN_KEY = "token";

export function getToken() {
  return getItem(TOKEN_KEY);
}

export function setToken(token) {
  setItem(TOKEN_KEY, token);
}

export function removeToken() {
  removeItem(TOKEN_KEY);
}
