const user = {
  state: {
    token: "",
    username: "",
    realname: "",
    welcome: "",
    avatar: "",
    permissionList: [],
    info: {},
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, { username, realname, welcome }) => {
      state.username = username;
      state.realname = realname;
      state.welcome = welcome;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    CHANGE_SETTING: (state, { key, value }) => {
      if (state.hasOwnProperty(key)) {
        state[key] = value;
      }
    },
  },
  actions: {
    changeSetting({ commit }, data) {
      commit("CHANGE_SETTING", data);
    },
    setUserInfo({ commit }, userInfo) {
      commit("SET_INFO", userInfo);
    },
    Logout({ commit }) {

    }
  },
};

export default user;
