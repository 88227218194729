import Confirm from "./ConfirmComponent.vue";

const ConfirmPlugin = {
  install(Vue, config) {
    // 创建一个子类
    const ConfirmConstructor = Vue.extend(Confirm);
    // 实例化这个子类
    const instance = new ConfirmConstructor();
    // 创建一个div元素，并把实例挂载到div元素上
    instance.$mount(document.createElement("div"));
    // 将el插入到body元素中
    document.body.appendChild(instance.$el);

    // 将事件处理方法绑定到实例上
    // 将事件处理方法绑定到实例上
    instance.handleCancel = () => {
      instance.config.onCancel();
      instance.close();
    };
    instance.handleConfirm = () => {
      instance.config.onOk();
      instance.close();
    };
    // 返回一个Promise
    Vue.prototype.$confirm = (message, title, options) => {
      return new Promise((resolve, reject) => {
        instance.config = { ...instance.config, ...options };
        instance.title = title || options.title
        instance.message = message || options.message
        instance.config.onOk = resolve;
        instance.config.onCancel = reject;
        instance.isShow = true;
      });
    };
  },
};

export default ConfirmPlugin;
