<template>
  <div>
    <input type="file" ref="fileInput" :accept="accept" :multiple="multiple" @change="handleFileChange" @dragover.prevent @drop="handleFileDrop" />
    <button @click="triggerUpload">Upload</button>
  </div>
</template>

<script>
export default {
  name: "Upload",
  props: {
    action: { required: true }, // 上传的地址
    headers: { type: Object, default: () => ({}) }, // 设置上传的请求头部
    multiple: { type: Boolean, default: false }, // 是否支持多选文件
    data: { type: Object, default: () => ({}) }, // 上传时附带的额外参数
    drag: { type: Boolean, default: false }, // 是否启用拖拽上传
    accept: { type: String, default: "" }, // 接受上传的文件类型
    showFileList: { type: Boolean, default: true }, // 是否显示已上传文件列表
    autoUpload: { type: Boolean, default: true }, // 是否在选取文件后立即进行上传
    fileList: { type: Array, default: () => [] }, // 上传的文件列表
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    triggerUpload() {
      if (this.file) {
        this.uploadFile(this.file);
      } else {
        this.$message.error("请选择文件进行上传");
      }
    },
    handleFileChange(event) {
      this.file = event.target.files[0];
      console.log(this.file);
      if (this.checkFile(this.file)) {
        if (this.autoUpload) {
          this.uploadFile(this.file);
        } else {
          this.$emit("file-selected", this.file);
        }
      } else {
        this.$message.error("文件类型或大小不符合要求，请重新选择文件");
        this.file = null;
      }
    },
    handleFileDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (this.checkFile(file)) {
        this.file = file;
        this.$emit("file-selected", this.file);
      } else {
        this.$message.error("文件类型或大小不符合要求，请重新选择文件");
      }
    },
    checkFile(file) {
      if (!this.accept) return true;
      // 检查文件类型和大小限制的逻辑，根据实际需求进行实现
      const allowedTypes = this.accept.split(",").map((type) => type.trim()); // 允许的文件类型列表，根据实际需求进行设置
      const maxSize = 1024 * 1024 * 10; // 允许的最大文件大小为10MB，根据实际需求进行设置
      const fileSize = file.size; // 文件大小，单位为字节（Byte）
      const fileType = file.type; // 文件类型（MIME）
      console.log("fileType", fileType);
      console.log("allowedTypes");
      console.log(allowedTypes);
      if (!allowedTypes.length || allowedTypes.includes(fileType)) {
        // 如果允许该文件类型或允许所有类型文件上传，则检查文件大小是否符合要求
        if (fileSize <= maxSize) {
          console.log("文件大小符合要求");
          // 文件大小符合要求时
          // 在这里执行文件上传操作，例如使用 axios 发送请求
          // 可以使用 file 对象获取文件信息，例如文件名称、文件类型、文件大小等
          // 具体的上传操作需要根据实际需求进行实现
          // ...
          // 上传成功或失败后，返回 true 表示文件符合要求，否则返回 false 表示文件不符合要求
          return true;
        } else {
          console.log("文件大小不符合要求");
          // 文件大小不符合要求时
          return false;
        }
      } else {
        // 文件类型不符合要求时
        return false;
      }
    },
    uploadFile(file) {
      let formData = new FormData();
      formData.append("file", file);
      if (this.data) {
        Object.keys(this.data).forEach((item) => {
          formData.append(item, this.data[item]);
        });
      }
      let httpurl = "/sys/common/upload";
      this.$api.uploadFile(httpurl, formData).then((res) => {
        if (res.code == 200) {
        } else {
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
